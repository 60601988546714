import React from 'react'

//Elements import
import { SectionTitle } from './SectionTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//Store import
import { dashboardState, dashboardMutations } from '../store/dashboard/_dashboard.main';

// Assets import
import { faUserCheck, faUserXmark, faTicket, faPencil, faUserGroup } from "@fortawesome/free-solid-svg-icons";

export const CardCodeMostUsed = () => {

    //Store
    const { usageCounterList } = dashboardState()
    const { setCardInfo, setShowModalEdit } = dashboardMutations()

    const getCodeMostUsed = usageCounterList![0]
    const noCodeUse = getCodeMostUsed?.useCounter - getCodeMostUsed?.registerCounter || 0

    const getStatus = getCodeMostUsed?.activeCode ? 'enable' : 'disable'

    const statusType = {
        enable: "bg-green-400 ring-green-300",
        disable: "bg-red-400 ring-red-300",
    }

    const handleEdit = () => {
        const setInfo = {
            code: getCodeMostUsed.code,
            status: getCodeMostUsed.activeCode,
            id: getCodeMostUsed.codeId,
            group: getCodeMostUsed.groupName,
            registerCounter: getCodeMostUsed.registerCounter,
            useCounter: getCodeMostUsed.useCounter
        }
        setCardInfo(setInfo)
        setShowModalEdit(true)
    }
  return (
    <div>
        <div>
            <SectionTitle
                title='Código más usado'
            />
            <article className='max-w-[320px] mx-auto'>
                <header className='mb-7'>
                    <FontAwesomeIcon
                        className={`cursor-pointer ml-auto block text-slate-700`}
                        onClick={ handleEdit }
                        icon={ faPencil }
                    />
                    <div className='flex flex-col gap-3 items-center justify-center text-center'>
                        <div className='relative shadow-lg shadow-slate-200 rounded-full flex flex-col gap-2 justify-center items-center w-40 h-40'>
                            <div className={`${statusType[getStatus]} w-4 h-4 rounded-full ring-2 absolute top-10 right-11`}></div>
                            <FontAwesomeIcon
                                className={`text-5xl text-slate-400`}
                                icon={ faTicket }
                            />
                            <p className='font-bold text-lg -mt-3 truncate w-32 text-slate-400'>{ getCodeMostUsed?.code }</p>
                        </div>
                        <p className='text-md font-extrabold mt-3 leading-3 text-2xl text-slate-700'>{ getCodeMostUsed?.useCounter }</p>
                        <p className='font-normal text-xs -mt-1 leading-3 w-40 text-slate-500'>Usos totales</p>
                    </div>
                </header>
                <div className='my-2 flex justify-between'>
                    <div className='flex gap-3 items-center'>
                    <FontAwesomeIcon
                        className={`text-md text-slate-400`}
                        icon={ faUserGroup }
                    />
                    <p className='font-thin text-sm leading-3 w-40 text-slate-500'>Grupo</p>
                    </div>
                    <p className='text-md font-bold text-slate-700'>{ getCodeMostUsed?.groupName }</p>
                </div>
                <div className='flex justify-between'>
                    <div className='flex gap-3 items-center'>
                    <FontAwesomeIcon
                        className={`text-md text-slate-400`}
                        icon={ faUserCheck }
                    />
                    <p className='font-thin text-sm leading-3 w-40 text-slate-500'>Código usado y registrado</p>
                    </div>
                    <p className='text-md font-bold text-slate-700'>{ getCodeMostUsed?.registerCounter }</p>
                </div>
                <div className='my-2 flex justify-between'>
                    <div className='flex gap-3 items-center'>
                    <FontAwesomeIcon
                        className={`text-md text-slate-400`}
                        icon={ faUserXmark }
                    />
                    <p className='font-thin text-sm leading-3 w-40 text-slate-500'>Código no registrado</p>
                    </div>
                    <p className='text-md font-bold text-slate-700'>{ noCodeUse }</p>
                </div>
            </article>
        </div>
    </div>
  )
}
