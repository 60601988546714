//Components import
import { LoginRegister } from "../components/login/LoginRegister";
import { OtpValidation } from "../components/otp/OtpValidation";

//Store import
import { loginState } from "../store/login/_login.main";

//Assets import
import "../assets/styles/views/login.css";

export const Login = () => {
  const { openOtp } = loginState();
  return <>{!openOtp ? <LoginRegister /> : <OtpValidation />}</>;
};
