import React,{ useState, useEffect, useRef } from 'react'

//Store import
import { processState } from "../../store/process/_process.main"

export const ProcessCountDown = () => {
    
    const [countDown, setCountDown] = useState(3)
    //Store
    const { event, fullDocumentInfo } = processState()

    const spanRef = useRef<HTMLSpanElement>(null)

    const launchIdenti5IDEvent = () => {
        delete fullDocumentInfo.faceImage
        document.dispatchEvent(
            new CustomEvent(event, {
                bubbles: true,
                composed: true,
                detail: { 
                    start: true,
                    fullDocumentInfo
                },
            })
        );
    }

    const stopInterval = (payload:any) => {
        clearInterval(payload)
        launchIdenti5IDEvent()
    }
    
    const handleNumberEffect = () => {
        spanRef.current?.classList.remove('count-down__number')
        setTimeout(()=>{
            spanRef.current?.classList.add('count-down__number')
        },20)
        setCountDown(countDown - 1)
    }

    useEffect(()=>{
        const countDownInterval = setInterval(()=>{
            countDown >= 2
            ? handleNumberEffect()
            : stopInterval(countDownInterval)
        },1000)
        return () => clearInterval(countDownInterval);
    },[countDown])

  return (

    <div className={`${countDown >= 2 ? '' : 'opacity-0'} count-down__wrapper`}>
        <span ref={ spanRef } className="count-down__number">{ countDown }</span>
    </div>
  )
}
