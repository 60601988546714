export enum DEVICE_NAMES{
    ANDROID = 'Android',
    WINDOWS_PHONE = 'Windows Phone',
    IOS = 'iOS',
    DESKTOP = 'Desktop'
}

export const validateDevice = () => {
    let w:any = window;
    
    const userAgent = navigator.userAgent || navigator.vendor || w.opera;

    if (/windows phone/i.test(userAgent)) {
        return DEVICE_NAMES.WINDOWS_PHONE;
    }

    if (/android/i.test(userAgent)) {
        return DEVICE_NAMES.ANDROID;
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !w.MSStream) {
        return DEVICE_NAMES.IOS;
    }
    return DEVICE_NAMES.DESKTOP
}