import React from 'react'

//Components import
import { HomeSection } from '../components/home/HomeSection';
import { Steps } from "../components/steps/Steps";
import { StepsDetails } from '../components/steps/StepsDetails';
import { Process } from "../components/process/Process";

//Store imports
import { processState } from '../store/process/_process.main'

export const Content = () => {

    const { showProcess } = processState()
    
    const getContent:any = {
        home: <HomeSection />,
        steps: <Steps />,
        details: <StepsDetails/>,
        process: <Process />
    }
    
    return (
        <>
            <div className="cover cover--relative overflow-y-auto">
                { getContent[showProcess] }
            </div>
        </>
    )
}