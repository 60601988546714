import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Keyboard, Pagination, Navigation, Autoplay } from "swiper";
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';

interface IFSlider{
    pagination:boolean;
    navigation:boolean;
    keyboard:boolean;
    autoplay:boolean;
    slides:ReactJSXElement[]|[]
}

const defaultProps = {
    pagination: false,
    navigation: true,
    keyboard: false,
    autoplay: false,
    slides:[]
}

export const Slider = (props:IFSlider) => {

    const getModules:any = {
        pagination: Pagination,
        navigation: Navigation,
        keyboard: Keyboard,
        autoplay: Autoplay
    }

    let setModules:any = []
    Object.entries(props).filter((key:any)=> {
        if(key[1] === true){
            setModules.push(getModules[key[0]])
        }
    })

  return (
    <>
        <Swiper
          className="p-3"
          slidesPerView={1}
          spaceBetween={30}
          keyboard={{
              enabled: true,
          }}
          autoplay={{
              delay: 2500,
              disableOnInteraction: false,
          }}
          pagination={{
              clickable: props.pagination,
          }}
          breakpoints={{
              "@0.00": {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              "@0.75": {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              "@1.00": {
                slidesPerView: 3,
                spaceBetween: 40,
              },
              "@1.50": {
                slidesPerView: 3,
                spaceBetween: 40,
              },
          }}
          navigation={ props.navigation }
          modules={ setModules }
        >
          {props.slides.map((item:any,index:number) => {
              return <SwiperSlide key={`m-${index}`}>{ item }</SwiperSlide>;
          })}
      </Swiper>
    </>
  )
}

Slider.defaultProps = defaultProps
