import React, { useState } from 'react'

//Elements import
import { ButtonIcon, InputSwitch } from "../../../elements/_elements.main";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

// Assets import
import { faAdd, faMicrochip, faWifi } from "@fortawesome/free-solid-svg-icons";

interface IFCardAddAndEditCreatorCode{
  index:number;
  type:'add'|'edit';
  id:number;
  icon:IconProp|string;
  code:string;
  group:string;
  useCounter:number;
  registerCounter:number;
  status:boolean;
  statusCard:boolean;
  isLoading:boolean;
  onChange:(e:any)=>void;
  onChangeSwitch:(e:any)=>void;
  onClick:(e:any)=>void;
}

const defaultProps = {
  index: 0,
  type: 'add',
  id: 0,
  icon: faAdd,
  code: "NOMBRE DEL CÓDIGO",
  group: "Grupo del código",
  useCounter: 0,
  registerCounter: 0,
  status: false,
  statusCard: false,
  isLoading: false,
  onChange:null,
  onChangeSwitch:null,
  onClick:null
}

export const CardAddAndEditCreatorCode = (props:IFCardAddAndEditCreatorCode) => {

  return (
    <article className={`mx-auto bg-gradient-to-b ${props.status ? 'to-purple-700 from-yellow-500' : 'from-slate-300 to-slate-400'} shadow-lg shadow-slate-200 relative transition-all w-[300px] min-w-[300px] h-48 text-white rounded-xl grid grid-rows-5 overflow-hidden`}>
      <header className='flex items-center justify-between row-span-3 px-5 relative mt-14 z-10'>
        <div className='absolute -top-10 opacity-50 z-50 flex justify-between items-center w-[88%]'>
            <FontAwesomeIcon
                className={`text-3xl text-gray-100`}
                icon={ faMicrochip }
            />
            <FontAwesomeIcon
                className={`text-2xl text-gray-100 rotate-90`}
                icon={ faWifi }
            />
        </div>
        <div className='w-36 relative'>
          <p className='font-thin text-xs leading-3 text-gray-200 text-left'>Código</p>
          <input
            autoComplete='off'
            type="text"
            name="code"
            className='placeholder-slate-100 bg-transparent w-[177px] truncate font-medium text-xl leading-3'
            placeholder='INGRESA CODIGO'
            value={ props.code }
            onChange={ (e)=> props.onChange(e) }
          />
          <input
            autoComplete='off'
            type="text"
            name="group"
            className='placeholder-slate-100 bg-transparent w-40 truncate text-xs font-medium -mt-3 relative'
            placeholder='Ingresa nombre de evento'
            value={ props.group }
            onChange={ (e)=> props.onChange(e) }
          />
        </div>
        <div>
          <p className='font-thin text-xs leading-3 text-gray-200'>Identificación:</p>
          <p className='font-medium leading-0 text-right'>{ props.index }</p>
        </div>
      </header>
      <footer className={`${ props.status ? 'bg-violet-900' : 'bg-slate-300' } flex items-center justify-between row-span-2 px-5`}>
        <div>
          <p className='text-2xl font-bold'>{ props.useCounter }</p>
          <p className='font-thin text-xs leading-3 text-gray-200'>Total usos</p>
        </div>
        <div className='z-40 flex justify-between gap-3 items-center'>
            {
              props.type === 'edit' &&
              <InputSwitch
                checked={ props.status }
                onChange={ props.onChangeSwitch }
              />
            }
            <div className={`bg-gradient-to-b flex justify-center items-center ${props.statusCard ? "to-purple-700 from-yellow-500" : ""} text-sm w-7 h-7 rounded-full`}>
              <ButtonIcon
                  icon={ props.icon }
                  onClick={()=>{props.onClick(props.id)}}
                  width="w-7"
                  height="h-7"
                  classes="text-white"
                  loading={ props.isLoading }
                  disabled={ !props.statusCard }
              />
            </div>
        </div>
      </footer>
    </article>
  )
}

CardAddAndEditCreatorCode.defaultProps = defaultProps
