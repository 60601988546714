import React, { useEffect, useRef } from 'react'

// Store imports
import { loginMutations } from "../../store/login/_login.main";

// Elements import
import { ButtonIcon, ModalClassic } from '../../elements/_elements.main'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Assets import
import { faStar } from "@fortawesome/free-solid-svg-icons";
import iconExit from "../../assets/icons/exit.svg";

export const LoginModalPoll = () => {
    
    //  Mutations
    const { setShowLoginModal } = loginMutations();

    const handleClose = ()=>{
        setShowLoginModal(false)
    }

    const handleClick = ()=>{
        window.open('https://forms.office.com/r/uAvujXbTvL','_blank','noopener')
    }

  return <>
        <ModalClassic size="small" classes="py-7 px-8 bg-gradient-to-r from-[#a5a3a3] to-green-400">
            <ButtonIcon
            icon={ iconExit }
            onClick={ handleClose }
            color="error"
            width="w-7"
            height="h-7"
            classes="modal__btn-close text-white"
            />
            <h2 className="text-lg text-center font-bold leading-5 mb-3 text-green-100">Prueba DEMO Identi5ID&reg;</h2>
            <p className="text-sm font-normal text-center text-gray-100">Para el desarrollo de la prueba, te invitamos a que tengas en cuenta
            las siguientes consideraciones.</p>
            {/* <p className="text-sm font-bold text-left text-gray-100">{ id }</p> */}
            <div className="mt-4">
                <button onClick={ handleClick } className="bg-middleBlue mx-auto gap-1 w-40 cursor-pointer flex flex-col justify-center items-center p-2 pb-3 rounded-lg">
                    <p className="m-0 font-bold text-white text-lg">Leer más</p>
                </button>
            </div>
        </ModalClassic>
    </>
}