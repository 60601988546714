import React, { useEffect, useRef } from 'react'

import "../../assets/styles/elements/elements.loaders.css";

export const LoaderPageText = () => {

  const progressElRef = useRef<any>(null)
  
  const handleProgressBar = () => {
    let accumProgress = 0
    const interval = setInterval(()=>{
      progressElRef.current.value = accumProgress
      accumProgress++
      if(accumProgress >= 100){
        clearInterval(interval)
      }
    },100)
  }

  useEffect(()=>{
    handleProgressBar()
  },[])

  return (
    <div className="loader-page-text">
        <div className="loader-page-text__content">
            <h1 className="loader-page-text__text">Cargando la solución...</h1>
            <progress className="document-reader__progress" ref={ progressElRef } value="0" max="100"></progress>
        </div>
        <div className="loader-page-text__background"></div>
    </div>
  )
}
