import create from "zustand";

// Types imports
import { IFAlerts } from "../../types/types.alerts";

export const alertsState = create<IFAlerts>(() => ({
    infoAlert:null,
    showAlert:false,
    showModalMessage: true,
}));
