interface IFPagination {
  background:string;
  arrowBackColor:string;
  arrowFrontColor:string;
  textColor:string;
  rowOptions:{
    value:string | number;
    text:string;
  }[];
  behind(e:any):void;
  following(e:any):void;
  selectChange(e:any):void;
  pageNumber:any;
  rowsOfPage:any;
  totalRecords:any;
}

const defaultProps = {
  background:'bg-gray-800',
  arrowBackColor:'',
  arrowFrontColor:'',
  textColor:'',
  rowOptions:null,
  behind:null,
  following:null,
  selectChange:null,
  pageNumber:1,
  rowsOfPage:10,
  totalRecords:0,
}

export const Pagination = (props:IFPagination) => {
  
  const getTotalPages = (props.totalRecords / props.rowsOfPage);
  const pages = parseInt(getTotalPages.toFixed())
  
  return (
    <>
      <div className={`${props.background} py-2 px-3 sm:px-11 flex items-center justify-between sm:justify-end rounded-t-lg`}>
        <div>
          <button
            className={`w-7 rounded-md mx-3 ${props.arrowBackColor}`}
            onClick={() => props.behind(pages) }
          >
            &larr;
          </button>

          <span className={`py-1 font-semibold ${props.textColor}`}>
            {`Page ${ props.pageNumber } -`} { pages > 0 ? pages : pages + 1 }
          </span>

          <button
            className={`w-7 rounded-md mx-3 ${props.arrowFrontColor}`}
            onClick={() => props.following(pages) }
          >
            &rarr;
          </button>
        </div>
        <select className={`border-gray-50 border rounded-md text-base`} onChange={ props.selectChange }>
          {
            props.rowOptions.map((option)=>(
              <option key={ option.value } className="bg-gray-50" value={ option.value }>{ option.text }</option>
            ))
          }
        </select>
      </div>
    </>
  );
};

Pagination.defaultProps = defaultProps
