// Axios import
import axios from "axios";

// Utils import
import { getConfig } from "./utils.headers";

//Modules Store import
import { CreatorCodesLoginStore } from "../modules/creatorcodes/_main.creatorcodes";

export const axiosInterceptor = axios.create();

axiosInterceptor.interceptors.request.use(
  async config => {
   config.headers = (await getConfig()).headers
   return config
  },
  error => {
    Promise.reject(error)
});

axiosInterceptor.interceptors.response.use(async (response) => {
  return response
}, async function (error) {
    // removeItem('auth')
    const setLoginActions = CreatorCodesLoginStore.loginActions.getState()
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const access_token = await setLoginActions.refreshAccessToken();
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
        return axiosInterceptor(originalRequest);
    }
  return Promise.reject(error);
});