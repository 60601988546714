import React,{ useState } from 'react'

// Elements import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonIcon, InputChoice, ModalClassic, SelectClassic, FormClassic } from '../../elements/_elements.main'

//Store import
import { processState, processMutations } from "../../store/process/_process.main"
import { loginActions } from "../../store/login/_login.main"
import { alertsMutations } from "../../store/alerts/_alerts.main"

//Utils import
import { validateDevice, DEVICE_NAMES } from "../../utils/utils.device";

// Assets import
import { faChevronRight, faClose } from "@fortawesome/free-solid-svg-icons";
import logoIdenti5IDFull from "../../assets/img/logoIdenti5ID-full.svg";

export const StepsProcessModal = () => {

    const { showStepsProcessModal, selectProcess, fullDocumentInfo } = processState();
    const { setShowStepsProcessModal, setSelectProcess, setProcess, setProcessMicroblink, setFullDocumentInfo } = processMutations();
    const { setInfoAlert, setShowAlert } = alertsMutations()
    const { generateTokenAuth } = loginActions()

    const handleSubmit = () => {
      if(selectProcess.processType === 'yes'){
        const authDevices = [DEVICE_NAMES.ANDROID, DEVICE_NAMES.IOS, DEVICE_NAMES.WINDOWS_PHONE]
        if(!authDevices.includes(validateDevice())){
          const setResponse = {
            title: "Esta opción sólo está disponible para dispositivos móviles.",
            message: "Te invitamos a que la pruebes allí",
            subtitle: "",
            alertType: "modal",
            onClick: null,
            outsideClick: false,
            cancelButton:{
              text:'',
              onClick:null
            },
            successButton: {
              text:'Entendido',
              onClick:() => setShowAlert(false)
            },
            type: "warning",
          };
          setInfoAlert(setResponse);
          handleClose()
          return
        }
      }

      generateTokenAuth("recognition")
      setProcess("recognition")
      let documentReader:boolean = true
      setProcessMicroblink(true)
      if(selectProcess.processType !== 'yes'){
        setProcessMicroblink(false)
        documentReader = false
      }
      
      setFullDocumentInfo({...fullDocumentInfo, documentReader})
      handleClose()
    }

    const handleClose = ()=>{
      setShowStepsProcessModal(!showStepsProcessModal)
    }

    const handleChange = (payload:any)=>{
      const { value, name } = payload.target
      setSelectProcess({...selectProcess, [name]: value})
    }

    const documentTypes = [{value:'co',text:"Cédula Colombiana"}]
    documentTypes.unshift({value:'', text:'Seleccione...'})

  return (
    <ModalClassic size="small" classes="py-7 px-8 bg-gray-100 text-primary max-w-[400px]">
        <ButtonIcon
          icon={ faClose }
          onClick={ handleClose }
          color=""
          width="w-7"
          height="h-7"
          classes="identi5ID-alert-modal__close"
      />

      <img src={ logoIdenti5IDFull} alt="Logo Identi5ID" className='object-cover w-44 my-5 mx-auto' />

      <FormClassic onSubmit={ handleSubmit }>
    
        <h2 className="text-lg text-center font-bold leading-5 mb-3">Deseo realizar el proceso:</h2>
        <div className="flex flex-col gap-3 bg-primary py-5 p-2 text-white rounded-lg">
          <div className='flex justify-center gap-5'>
            <label className='text-sm font-normal cursor-pointer' htmlFor="no">
              Sin lectura de documento
            </label>
            <InputChoice
              type="radio"
              name="processType"
              id="no"
              height="h-6"
              width='w-6'
              value="no"
              checked={  selectProcess.processType === "no" }
              onChange={ handleChange }
            />
          </div>
          <div className="flex justify-center gap-3">
            <label className="text-sm font-normal cursor-pointer" htmlFor="yes">
              Con lectura de documento
            </label>
            <InputChoice
              type="radio"
              classes="border-secondary border-2"
              name="processType"
              id="yes"
              height="h-6"
              width='w-6'
              value="yes"
              checked={ selectProcess.processType === "yes" }
              onChange={ handleChange }
            />
          </div>
          {
            selectProcess.processType === "yes" &&
            <div className="rounded-full overflow-hidden w-[75%] mx-auto">
              <SelectClassic
                name="documentType"
                classes="border-secondary w-full px-3 h-9 bg-white rounded-full text-primary"
                onChange={ handleChange }
                options={ documentTypes }
                selected="co"
                required={ true }
              />
            </div>
          }
        </div>
        <div className='flex'>
          <button onClick={ handleClose } className="text-secondary border-2 mt-5 border-secondary rounded-full mx-auto cursor-pointer flex justify-center items-center py-2 px-4">
              <p className="mr-1 font-light text-base">Cancelar</p>
          </button>
          <button className="text-white bg-secondary border-2 mt-5 border-secondary rounded-full mx-auto cursor-pointer flex justify-center items-center py-2 px-4">
              <p className="mr-1 font-light text-base">Aceptar</p>
              <FontAwesomeIcon
                  className={`bg-secondary w-4 h-w-4 text-white rounded-full p-1`}
                  icon={ faChevronRight }
              />
          </button>
        </div>
      </FormClassic>
    </ModalClassic>
  )
}
