import React, { useState } from 'react'

//Elements import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Assets import
import { faEye, faEyeSlash, faMicrochip, faWifi, faUserCheck, faUserXmark, faTicket, faPencil } from "@fortawesome/free-solid-svg-icons";

interface IFCardCreatorCode{
  index:number;
  id:number;
  code:string;
  group:string;
  useCounter:number;
  registerCounter:number;
  status:boolean;
  onClick:(e:any)=>void;
}

const defaultProps = {
  index: 0,
  id: 0,
  code: "CODE_NAME",
  group: "",
  useCounter: 0,
  registerCounter: 0,
  status: false,
  onClick:null
}

export const CardCreatorCode = (props:IFCardCreatorCode) => {

  const [showDetail, setShowDetail] = useState(false);

  const noCodeUse = props.useCounter - props.registerCounter

  return (
    <article className={`bg-gradient-to-b ${props.status ? 'to-purple-700 from-yellow-500' : 'from-slate-300 to-slate-400'} ${showDetail ? 'card-code-creator__perspective' : ''} shadow-lg relative transition-all w-[300px] min-w-[300px] h-48 text-white rounded-xl grid grid-rows-5 overflow-hidden`}>
        <div className={`${props.status ? 'bg-violet-900' : 'bg-slate-400' } ${showDetail ? 'left-0 opacity-100 w-full h-full' : 'left-96 w-0 h-0 opacity-0'} absolute z-30 p-5 bottom-0 transition-all ease-linear duration-100 truncate`}>
          <div className={`relative z-20 flex flex-col justify-center h-full transition-all ease-linear duration-700 ${showDetail ? 'opacity-100' : 'opacity-0'}`}>
            <div className='absolute top-0'>
              <p className='font-medium text-base truncate w-[177px]'>
                { props.code }
              </p>
            </div>
            <div className='mb-0.3 flex justify-between'>
              <div className='flex gap-3 items-center'>
                <FontAwesomeIcon
                  className={`text-sm text-gray-100`}
                  icon={ faUserCheck }
                />
                <p className='font-thin text-md leading-3 w-40 text-gray-200'>Código usado y registrado</p>
              </div>
              <p className='text-lg font-bold'>{ props.registerCounter }</p>
            </div>
            <div className='my-0.3 flex justify-between'>
              <div className='flex gap-3 items-center'>
                <FontAwesomeIcon
                  className={`text-sm text-gray-100`}
                  icon={ faUserXmark }
                />
                <p className='font-thin text-md leading-3 w-40 text-gray-200'>Código no registrado</p>
              </div>
              <p className='text-lg font-bold'>{ noCodeUse }</p>
            </div>
            <div className='my-0.3 flex justify-between'>
              <div className='flex gap-3 items-center'>
                <FontAwesomeIcon
                  className={`text-sm text-gray-100`}
                  icon={ faTicket }
                />
                <p className='font-thin text-md leading-3 w-40 text-gray-200'>Total usos</p>
              </div>
              <p className='text-lg font-bold'>{ props.useCounter }</p>
            </div>
          </div>
          <FontAwesomeIcon
            className={`text-9xl absolute z-10 opacity-10 top-0 bottom-0 left-0 right-0 my-auto ml-auto rotate-[90deg] text-gray-100`}
            icon={ faTicket }
          />
        </div>
      <header className='flex items-center justify-between row-span-3 px-5 relative mt-14 z-10'>
        <div className='absolute -top-10 opacity-50 z-50 flex justify-between items-center w-[88%]'>
            <FontAwesomeIcon
                className={`text-3xl text-gray-100`}
                icon={ faMicrochip }
            />
            <FontAwesomeIcon
                className={`text-2xl text-gray-100 rotate-90`}
                icon={ faWifi }
            />
        </div>
        <div className='truncate w-36'>
          <p className='font-thin text-xs leading-3 text-gray-200'>Código</p>
          <p className='font-medium text-xl leading-0'>{ props.code }</p>
          <p className='text-xs font-thin -mt-1'> { props.group } </p>
        </div>
        <div>
          <p className='font-thin text-xs leading-3 text-gray-200'>Identificación:</p>
          <p className='font-medium leading-0 text-right'>{ props.index }</p>
        </div>
      </header>
      <footer className={`${ props.status ? 'bg-violet-900' : 'bg-slate-300' } flex items-center justify-between row-span-2 px-5`}>
        <div>
          <p className='text-2xl font-bold'>{ props.useCounter }</p>
          <p className='font-thin text-xs leading-3 text-gray-200'>Total usos</p>
        </div>
        <div className='z-40 flex justify-between gap-3'>
          {!showDetail &&
            <FontAwesomeIcon
              className={`cursor-pointer text-gray-100`}
              onClick={()=>{props.onClick(props)}}
              icon={ faPencil }
          />
          }
          {
            props.status &&
            <FontAwesomeIcon
                className={`cursor-pointer text-gray-100`}
                icon={ !showDetail ? faEye : faEyeSlash }
                onClick={() => setShowDetail(!showDetail)}
            />
          }
        </div>
      </footer>
    </article>
  )
}

CardCreatorCode.defaultProps = defaultProps
