// React imports
import { useState } from "react";
// Utils imports
import { dinamicValidate } from "../../utils/utils.form";
// Styles import
import "../../assets/styles/elements/elements.inputs.css";

//Props
interface IFInputForm {
  placeholder: string;
  type: string;
  name: string;
  value: string;
  color: string;
  width: string;
  height: string;
  classes: string;
  pattern: string;
  minLength: number;
  maxLength: number;
  required: boolean;
  autoFocus: boolean;
  readOnly: boolean;
  onChange(e: any): void;
  onFocus(e: any): void;
  hint: string;
}

//valor x default
const defaultProps = {
  placeholder: "Text input",
  type: "text",
  name: "",
  value: "",
  color: "primary",
  width: "w-full md:w-72",
  height: "h-9",
  classes: "ring-primary ring-opacity-0",
  pattern: null,
  minLength: 0,
  maxLength: 100,
  required: true,
  autoFocus: false,
  readOnly: false,
  onChange: null,
  onBlur: null,
  onFocus: null,
  hint: "Insert text",
};

//component
export const InputForm = (props: IFInputForm) => {
  // State
  const [interaction, setInteraction] = useState(false);

  const validateError = () => {
    if (interaction && props.required) {
      return (
        props.value.length === 0 ||
        props.value.length < props.minLength ||
        props.value.length > props.maxLength ||
        !dinamicValidate({ type: props.type, value: props.value })
      );
    } else {
      return false;
    }
  };

  return (
    <>
      <div className={`input-form ${props.width} ${props.classes}`}>
        <label
          className={`input-form__lable ${
            props.value ? " opacity-100 left-2" : "opacity-0 left-3"
          } 
          ${validateError() ? "bg-error" : "bg-primary"}`}
        >
          {props.placeholder}
        </label>
        <input
          required={props.required}
          minLength={props.minLength}
          maxLength={props.maxLength}
          autoFocus={props.autoFocus}
          placeholder={props.placeholder}
          pattern={props.pattern}
          type={props.type}
          name={props.name}
          className={`
          input-classic input-classic--primary ${props.height} 
          ${validateError() ? "input-classic--error" : ""}
          `}
          value={props.value}
          onChange={props.onChange}
          onBlur={() => setInteraction(true)}
          onFocus={ props.onFocus }
        />
      </div>
    </>
  );
};

InputForm.defaultProps = defaultProps;
