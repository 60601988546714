import React, {useRef,useEffect } from 'react'

//Styles import
import '../../assets/styles/elements/elements.inputs.css'

interface IFInputPinGenerator {
  clean: boolean;
  disabled: boolean;
  length: number;
  onComplete: (payload: any) => void;
  onChange: (e: any) => void;
  width: string;
  height: string;
  classes: string;
  borderRadius: string;
}

const defaultProps = {
  clean: true,
  disabled: false,
  length: 4,
  onComplete: null,
  onChange: null,
  width: "w-8",
  height: "h-8",
  classes: "",
  borderRadius: "lg",
};

export const InputPinGenerator = (props:IFInputPinGenerator) => {
  
  const inputReference = useRef<Array<HTMLInputElement>>([])
  inputReference.current = []

  const authLength = props.length - 1

  useEffect(()=>{
    !inputReference.current[0].value && inputReference.current[0].focus();
  },[props.disabled])

  const addInputReference = (payload:any) => {
    if(payload && !inputReference.current.includes(payload)){
      inputReference.current.push(payload)
    }
  }

  const validateKeyPress = (e:any, index:number) => {
    const inputElement = inputReference.current[index]
    const beforeInputElement = inputReference.current[index-1]
    const iWantUseBakspace = e.key === "Backspace" && e.keyCode === 8 ? true : false
    const iWantUseDelete = e.key === "Delete" && e.keyCode === 46 ? true : false

    if(iWantUseBakspace || iWantUseDelete){    
      if(inputElement.value === '' && inputReference.current[index] !== inputReference.current[0]){
        beforeInputElement.focus()
      }
    }
  }

  const getNumbers = () => { 
    let numbers:Array<number> = []
    inputReference.current.forEach((item:any)=>{
      item.value && numbers.push(item.value)
    })

    const OTP = numbers.join('')
    props.onChange && props.onChange(OTP)

    if(numbers.length === props.length){
      props.clean && setTimeout(()=>cleanInputs(),500)
      props.onComplete && props.onComplete(OTP)
    }
  }

  const cleanInputs = () => {
    inputReference.current.forEach((item)=>{
      item.value = ''
    })
    inputReference.current[0].focus()
  }

  const validateChange = (e:any, index:number) => {
    const inputElement = inputReference.current[index];
    const nextInputElement = inputReference.current[index + 1];

    //Validate change when prop is passed
    props.onChange && props.onChange(inputElement);

    //Validate and distributed all numbers copied too
    if (inputElement.value.length > 1) {
      const transformNumberPasted = inputElement.value
        .split("")
        .splice(0, props.length);

      if (
        inputReference.current[index] !== inputReference.current[authLength]
      ) {
        transformNumberPasted.forEach((number) => {
          let position = index + 1;
          inputReference.current[index].value = number;

          if (position <= authLength) {
            inputReference.current[position].disabled = false;
            inputReference.current[position].focus();
          }
          index++;
        });

        getNumbers();
        return;
      } else {
        inputReference.current[authLength].value = transformNumberPasted[0];
      }
    }

    if (inputElement.value) {
      let position = index + 1;
      if (position <= authLength) {
        nextInputElement.disabled = false;
        nextInputElement.focus();
      }
    }

    getNumbers();
  }

  const focusReturn = (e:any)=>{
    if(e.target.value){
      let value = e.target.value
      e.target.value = ''
      e.target.value = value
    }
  }
  
  const rounded = (payload:any) => {
    const borderRadius:any = {
      rounded: 'rounded-'+ payload,
    }
    return `${borderRadius["rounded"]}`
  }

  return (
    <>
      <div className='flex' onClick={(e)=> focusReturn(e) }>
      {
        [...Array(props.length)].map((item, index)=>{
          return <input
            className={
              `input-pin  ${ props.classes } ${ props.width } ${ props.height } ${rounded(props.borderRadius)}`
            }
            // type="number"
            type="tel"
            ref={ addInputReference }
            key={ index }
            name=""
            maxLength = { 1 }
            disabled= { props.disabled ? props.disabled : index === 0 ? false:true }
            onKeyUp={(e)=> validateKeyPress(e, index) }
            onChange={(e)=> validateChange(e, index) }
          />
        })

      }
      </div>
    </>
  )
}

InputPinGenerator.defaultProps = defaultProps
