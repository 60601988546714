import React, {useEffect} from 'react';

// Styles import
import "../../assets/styles/elements/elements.alerts.css";

//Assets import
import success from "../../assets/icons/success.svg";
import warning from "../../assets/icons/warning.svg";
import error from "../../assets/icons/error.svg";

interface IFAlertMessagesModalIdenti5ID {
  classes: string;
  type: "success" | "warning" | "error"
  title: string;
  message: string;
  subtitle: string;
  cancelButton?:{
    text:string;
    onClick:(e: any) => void;
  };
  successButton?:{
    text:string;
    onClick:(e: any) => void;
  };
  code: number;
  rotate?:'yes'|'no';
  outsideClick:boolean;
  closeAlert(e: any): void;
}

const defaultProps = {
  classes: "",
  type: "warning",
  title: "Write title!",
  message: "Write information message!",
  subtitle: "Write subtitle!",
  cancelButton:{
    text:'Cancel',
    onClick:null
  },
  successButton: {
    text:'Try again...',
    onClick:null
  },
  code: 0,
  outsideClick: false,
  closeAlert: null
}

export const AlertMessagesModalIdenti5ID = (props: IFAlertMessagesModalIdenti5ID) => {

  const errorCode:any = {
    400: "error",
    500: "error",
    200: "success"
  }

  const iconType:any = {
    "success": {
      icon: success,
      color: {
        to: "to-green-600",
        text: "text-white",
        400: "bg-green-400",
        600: "bg-green-600",
        button: "border-green-600"
      },
      message:{
        text: "text-white",
      },
      subtitle:{
        text: "text-white",
      }
    },
    "warning": {
      icon: warning,
      color: {
        to: "to-amber-600",
        text: "text-white",
        400: "bg-amber-400",
        600: "bg-amber-700",
        button: "border-amber-600"
      },
      message:{
        text: "text-white",
      },
      subtitle:{
        text: "text-white",
      }
    },
    "error": {
      icon: error,
      color: {
        to: "to-red-600",
        text: "text-white",
        400: "bg-red-400",
        600: "bg-red-600",
        button: "border-red-600"
      },
      message:{
        text: "text-white",
      },
      subtitle:{
        text: "text-white font-bold",
      }
    }
  }

  //Validate if props code is passed or not
  const type = props.code > 0 ? errorCode[props.code] : props.type;
  const icon = iconType[type].icon

  const color = (payload:any) => {
    return iconType[type].color[payload]
  }
  
  const message = (payload:any) => {
    return iconType[type].message[payload]
  }
  
  const subtitle = (payload:any) => {
    return iconType[type].subtitle[payload]
  }

  const title = props.code ? `Código: ${props.code}` : props.title

  const handleGlobalClick = (e:any) =>{
    if(props?.outsideClick){
      props?.closeAlert && props?.closeAlert(e.target.classList.contains('alert'))
    }
    props?.closeAlert && props?.closeAlert(e.target.classList.contains('closeAlert'))
  } 

  useEffect(()=>{
      window.addEventListener("click", handleGlobalClick)        
      //CleanUp click listener
      return () => window.removeEventListener('click', handleGlobalClick)
  })

  return (
    <div className="identi5ID-alert-modal bg-gray">
      <div className="alert identi5ID-alert-modal__background"></div>
      <div className={`identi5ID-alert-modal__content ${props.rotate === 'yes' ? 'identi5ID-alert-modal__content--rotate':''}`}>
        <button onClick={ handleGlobalClick } className="identi5ID-alert-modal__close closeAlert">
          X
        </button>
        <div className="identi5ID-alert-modal__details">
          <div className="identi5ID-alert-modal__details-icon">
            <span className={`identi5ID-alert-modal__icon identi5ID-alert-modal__icon--pulse ${ color(600) }`}></span>
            <span className={`identi5ID-alert-modal__icon relative ${ color(600) }`}>
              <img src={ icon } alt={`Icon ${ type }`} className={`${ color(400) } identi5ID-alert-modal__icon identi5ID-alert-modal__icon--shadow`} />
            </span>
          </div>
          <div className="identi5ID-alert-modal__details-text">
            <h2 className={`identi5ID-alert-modal__title ${ color("text") }`}>{ title }</h2>
            <p className={`identi5ID-alert-modal__message ${ message("text") }`}>{props.message}</p>
            <p className={`identi5ID-alert-modal__subtitle ${ subtitle("text") }`}>{props.subtitle}</p>
          </div>
        </div>
        <footer className="identi5ID-alert-modal__footer">
          {props.cancelButton?.text &&
            <button onClick={ props.cancelButton?.onClick } className={`identi5ID-alert-modal__footer-button ${ color("button") }`}>
              { props.cancelButton.text }
            </button>
          }
          <button onClick={ props.successButton?.onClick } className={`identi5ID-alert-modal__footer-button identi5ID-alert-modal__footer-button--success }`}>
            <span className="identi5ID-alert-modal__footer-button__text">{ props.successButton?.text }</span>
          </button>
        </footer>
      </div>
    </div>
  );
};

AlertMessagesModalIdenti5ID.defaultProps = defaultProps;