import { useEffect } from "react";

//Elements import
import {
  InputPinGenerator,
  ButtonLink,
  Waves,
  ButtonIcon,
} from "../../elements/_elements.main";

//Store import
import {
  loginState,
  loginMutations,
  loginActions,
} from "../../store/login/_login.main";

//Assets import
import arrowBack from "../../assets/icons/arrow_back.svg";
import logoIdenti5IDFull from "../../assets/img/logoIdenti5ID-full.svg";

// Ga import
import ReactGA from "react-ga4";

//Language from Browser
const languages = window.navigator.language.substring(0, 2) ?? "en";

export const OtpValidation = () => {
  const { setOpenOtp, maxUseFromOTP, endSessionAndNotify } = loginMutations();
  const { reSendOtp, validateOtp } = loginActions();
  const { temporaryData, loadingResendOTP, otpAttempts } = loginState();

  const { email } = temporaryData ?? {};
  const language = `${languages}`;

  const length = 4;
  const totalAttemptsAuth = 4;

  let validateIntervalSession: any;

  const handleChange = (payload: any) => {
    if (payload.length === length) {
      ReactGA.event({
        category: "login",
        action: "Submit_form_otp",
      });

      validateOtp({ ...temporaryData, language, pin: payload });
    }
  };

  const handleSession = () => {
    const date: any = temporaryData?.expirationDateOtp;
    const countDownDate = new Date(date).getTime();

    validateIntervalSession = setInterval(() => {
      const now = new Date().getTime();
      const diff = countDownDate - now;
      if (diff < 0) {
        clearInterval(validateIntervalSession);
        endSessionAndNotify();
      }
    }, 1000);
  };

  const handleResendOTP = () => {
    ReactGA.event({
      category: "login",
      action: "Submit_form_resend_otp",
    });

    if (otpAttempts < totalAttemptsAuth) {
      reSendOtp({ ...temporaryData, language });
    } else {
      ReactGA.event({
        category: "login",
        action: "Exceeded_form_resend_otp",
      });

      maxUseFromOTP();
    }
  };

  const backButton = () => {
    setOpenOtp(false);
  };

  useEffect(() => {
    handleSession();

    //Clear interval
    return () => clearInterval(validateIntervalSession);
  }, [temporaryData?.expirationDateOtp, otpAttempts]);

  return (
    <>
      <div className="cover--relative flex--center bg-white overflow-hidden relative">
        <div className="p-0 w-[320px] sm:w-[400px] h-[390px] justify-center sm:p-8 flex text-center bg-gray-100 shadow-lg shadow-slate-500 rounded-lg overflow-hidden z-20 relative">
          <div
            className={`${
              !loadingResendOTP && "hidden"
            } bg-primary/50 absolute h-full w-full z-50`}
          ></div>
          <div className="absolute left-12 top-12 z-50">
            <ButtonIcon
              width="w-5"
              height="h-5"
              classes="p-1 flex absolute -top-5 -left-5 bg-secondary"
              icon={arrowBack}
              onClick={backButton}
            />
          </div>
          <div className="h-auto relative flex--center p-5 py-7 rounded-none sm:rounded-xl">
            <div className="">
              <div className="my-3">
                <a
                  href="https://www.identi5id.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-block"
                >
                  <img
                    src={logoIdenti5IDFull}
                    alt="logo Identi5ID"
                    className="object-cover w-44"
                  />
                </a>
              </div>
              <div className="relative mb-7 mx-auto px-4 text-sm w-60 leading-4 text-primary">
                <p>
                  Por favor ingresa el código que hemos enviado al correo{" "}
                  <span className="font-bold block truncate">{email}</span>
                </p>
              </div>
              <div className="mx-auto relative overflow-hidden sm:w-[320px] h-auto flex--center flex-col py-5 rounded-lg bg-primary">
                <header className="mb-3">
                  <h3 className="text-center text-white text-md">
                    Código de <span className="font-bold">verificación</span>
                  </h3>
                </header>
                <div className="text-center z-20">
                  <InputPinGenerator
                    disabled={loadingResendOTP}
                    length={length}
                    width="w-11"
                    height="h-11"
                    clean={true}
                    classes={`bg-primary border-2 border-secondary text-white ${
                      loadingResendOTP
                        ? "bg-primary"
                        : "disabled:bg-purple-100 disabled:opacity-10"
                    }`}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <ButtonLink
                classes="relative text-xs text-main font-bold mt-3 hover:text-primary z-50"
                loading={loadingResendOTP}
                text="¿No recibiste un código de verificación?"
                onClick={handleResendOTP}
              />
            </div>
          </div>
        </div>
        <Waves
          classes="top-[calc(100vh-90vh)] sm:top-[initial] sm:bottom-0 sm:h-[50vh] z-10"
          color="238, 242, 255"
        />
        <div className="bg-[#eef2ff] bottom-0 h-[calc(100vh-225px)] w-full absolute sm:hidden"></div>
      </div>
    </>
  );
};
