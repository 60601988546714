import React from "react";

//Components import
import { LoginInviteCode } from "../components/login/LoginInviteCode";
import { Login } from "./Login";

//Store imports
import { loginState } from "../store/login/_login.main";

export const Invite = () => {
  const { showLogin } = loginState();

  return (
    <>
      <div className="h-full overflow-y-auto">
        {showLogin ? <Login /> : <LoginInviteCode />}
      </div>
    </>
  );
};
