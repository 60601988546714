import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";

//External libraries
import ReCAPTCHA from "react-google-recaptcha";

//Elements import
import {
  ButtonClassic,
  FormClassic,
  InputPassword,
} from "../../elements/_elements.main";

//Store import
import { loginState, loginActions, loginMutations } from "../../store/login/_login.main";

//Assets import
import background from "../../assets/img/backgroundDemo.png";
import logoIdenti5IDFull from "../../assets/img/logoIdenti5ID-full.svg";
import invitationCode from "../../assets/img/invitationCode.jpg";

import { dataDecrypt } from "../../utils/utils.encrypt-decrypt";

// Ga import
import ReactGA from "react-ga4";

export const LoginInviteCode = () => {

  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const { validateCode, getTermsAndConditions } = loginActions();
  const { loadingInvite } = loginState();

  const [code, setCode] = useState("");

  const handleSubmit = () => {
    ReactGA.event({
      category: "login",
      action: "Submit_form_invite_code",
    });

    recaptchaRef.current &&
      recaptchaRef.current.executeAsync().then((res: any) => {
        if (res != null) {
          validateCode({ Code: code });
        }
        recaptchaRef.current?.reset();
      });
  };

  useEffect(() => {
    getTermsAndConditions();
  }, []);

  return (
    <>
      <div className="absolute grid grid-cols-2 h-full w-full bottom-0 z-10">
        <div className="shadow-4xl shadow-white bg-white col-span-2 sm:col-span-1 order-2 sm:order-1 z-20">
          {/* <img
            src={ background }
            alt="" 
            className='h-full w-full object-cover min-w-[700px]'/> */}
        </div>
        <img
          src={invitationCode}
          alt="Invitation Code"
          className="object-cover min-w-[500px] h-full w-full col-span-2 sm:col-span-1 order-1 sm:order-2"
        />
      </div>
      <div className="cover--relative flex items-end justify-center sm:items-center sm:justify-start sm:ml-32 overflow-y-auto sm:p-10">
        <div className="rounded-xl bg-gray-100 p-8 sm:p-16 max-w-xs sm:max-w-xl shadow-lg shadow-slate-500 font-poppins z-10 mb-12">
          <header className="mb-7">
            <div className="">
              <Link
                to="https://www.identi5id.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={logoIdenti5IDFull}
                  alt="logo Identi5ID"
                  className="mb-7 mx-auto sm:ml-0 object-cover w-52"
                />
              </Link>
            </div>
            <h3 className="text-center sm:text-left text-primary font-regular text-xl sm:text-2xl mb-4 leading-6">
              Ahora puedes poner tu{" "}
              <span className="font-bold">mejor cara</span>
            </h3>
            <p className="text-gray-500 text-left text-md sm:text-lg leading-5">
              en este espacio encontrarás la{" "}
              <span className="font-bold">tecnología de biometría facial </span>
              al servicio de tu empresa, de forma{" "}
              <span className="font-bold">segura, fácil</span> y{" "}
              <span className="font-bold">adaptable.</span>
            </p>
          </header>
          <footer className="mx-auto">
            <FormClassic onSubmit={handleSubmit}>
              <p className="text-primary font-medium text-center mb-4 leading-5">
                Ingresa AQUÍ el código de{" "}
                <span className="font-bold">invitación</span>
              </p>
              <InputPassword
                autoFocus={ true }
                value={code}
                placeholder="Código de invitación"
                onChange={(e) => setCode(e.target.value)}
                classes="mb-7 mx-auto"
              />
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey="6LcV6useAAAAAMpj70AirYWY3Z-6kWQg_ofK92sn"
                onChange={handleSubmit}
                size="invisible"
              />
              <ButtonClassic
                loading={loadingInvite}
                type="submit"
                color="secondary"
                text="Entrar"
                classes="block mx-auto"
              />
            </FormClassic>
          </footer>
        </div>
      </div>
    </>
  );
};
