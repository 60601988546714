import create from "zustand";

//Types import
import { IFLogin } from "../../types/types.login"

export const loginState = create<IFLogin>(() => ({
 loadingLoginCreatorCode: false,
 userInformation:{
     username: '',
     password: '',
 },
 isLogged: false,
 showModalLogout: false,
}));
