import React from 'react';
import { Routes, Route } from 'react-router-dom'

//Views import
import { DashboardCreatorCode } from '../views/DashboardCreatorCode'

export const RoutesPrivate = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<DashboardCreatorCode/>} />
      </Routes>  
    </>
  )
}
