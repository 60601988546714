import React, { useEffect } from 'react'

import { AlertMessagesModalIdenti5ID, AlertMessageSmall, AlertMessagesModalIdenti5IDDocument } from "../_elements.main";

interface IFAlertMessageType{
    content:null | {
        code:number;
        title:string;
        message:string;
        subtitle:string;
        cancelButton?:{
            text:string;
            disabled:boolean;
            onClick:(e: any) => void;
        };
        successButton?:{
            text:string;
            disabled:boolean;
            onClick:(e: any) => void;
        };
        icon?:string;
        rotate?:'yes'|'no';
        alertType: "toast" | "modal",
        type: "success" | "warning" | "error"
    },
    outsideClick:boolean;
    onClick(e:any):void
}

const defaultProps = {
    content:{
        code: 0,
        title: "Write title!",
        message: "Write information message!",
        subtitle: "",
        cancelButton:{
            text:'Cerrar',
            onClick:null
        },
        successButton: {
            text:'Intentar nuevamente',
            onClick:null
        },
        rotate: 'no',
        icon: "",
        alertType: "modal",
        type: "warning",
    },
    outsideClick: true,
    onClick: null
}

export const AlertMessageType = (props:IFAlertMessageType) => {
    
    const content = props.content
    const type = content?.alertType || "toast"

    const alertType:any = {
        toast: <AlertMessageSmall 
                code={ content?.code }
                type={ content?.type }
                title={ content?.title}
                message={ content?.message }
                done={ props.onClick }
            />,
        modal: <AlertMessagesModalIdenti5ID
                code={ content?.code }
                type={ content?.type }
                title={ content?.title}
                message={ content?.message }
                subtitle={ content?.subtitle}
                cancelButton={ content?.cancelButton }
                successButton={ content?.successButton }
                rotate={ content?.rotate }
                outsideClick={ props.outsideClick }
                closeAlert={ props.onClick }
            />,
        document: <AlertMessagesModalIdenti5IDDocument
                code={ content?.code }
                type={ content?.type }
                title={ content?.title}
                message={ content?.message }
                icon={ content?.icon }
                cancelButton={ content?.cancelButton }
                successButton={ content?.successButton }
                rotate={ content?.rotate }
                outsideClick={ props.outsideClick }
                closeAlert={ props.onClick }
            />,
    }

    useEffect(()=>{
        return ()=>{}
    })

    return(
        <>
            <div className="absolute top-0 right-0 left-0 z-[9999]">
                {alertType[type]}
            </div>
        </>
    );
}

AlertMessageType.defaultProps = defaultProps