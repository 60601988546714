import create from "zustand";

//Types import
import {IFLogin} from "../../types/types.login"

export const loginState = create<IFLogin>(() => ({
 loadingInvite: false,
 loadingSignIn: false,
 loadingResendOTP: false,
 temporaryData: null,
 inviteCode:'',
 showLogin:false,
 showLoginModal:false,
 openOtp:false,
 otpAttempts: 0,
 authentication:false,
 tokenAuth:null,
 infoTerms:null,
 loadingPage:{
    status: false,
    type:"circle"
 },
}));
