import create from "zustand";

//Store import
import { loginMutations, loginState } from "../../store/login/_login.main";
import { userMutations } from "../../store/user/_user.main";
import {
  processMutations,
  processState,
} from "../../store/process/_process.main";
import { alertsMutations } from "../alerts/_alerts.main";

//Utils import
import { restPost, restGet, getTokenAuth } from "../../utils/utils.rest";

//Tickets import
import {
  USER_TOKEN_AUTH,
  URL_VALIDATE_CODE,
  URL_CREATE,
  URL_VALIDATE_OTP,
  URL_REGENERATE_OTP,
  URL_LOGIN_AUTH,
  URL_GET_TERMS_AND_CONDITIONS,
  URL_ACCEPTED_TERMS_AND_CONDITIONS,
} from "../../app.tickets";

// Ga import
import ReactGA from "react-ga4";

//Store Getters
const setLoginMutation = loginMutations.getState();
const setUserMutation = userMutations.getState();
const setAlertsMutation = alertsMutations.getState();
const setProcessMutation = processMutations.getState();

export const loginActions = create(() => ({
  getTermsAndConditions: async () => {
    try {
      const res = await restGet({ url: URL_GET_TERMS_AND_CONDITIONS });
      const { id, description } = res.data.data;
      setLoginMutation.setInfoTerms({ id, description });
    } catch (error) {
      console.error("ERR", error);
    }
  },

  validateCode: async (payload: any) => {
    setLoginMutation.setLoadingInvite(true);

    const errorCode: any = {
      400: "warning",
      500: "error",
      200: "success",
    };

    try {
      const rest = await restPost({ url: URL_VALIDATE_CODE, data: payload });
      const { id } = rest.data.data;
      setLoginMutation.setInviteCode(id);
      setLoginMutation.setShowLogin(true);

      ReactGA.event({
        category: "login",
        action: "Success_form_invite_code",
      });
    } catch (err: any) {
      const { status } = err.response;
      const { message } = err.response.data;

      const messageInvite =
        "Te invitamos a que solicites tú código de invitación al correo electrónico: info@appinit.co";

      let alertTitle: any = "";
      let alertText: any = "";
      switch (status) {
        case 400:
          alertTitle = "El código de invitación no es válido.";
          alertText = messageInvite;
          break;
        default:
          alertTitle = "Error en el proceso";
          alertText = message;
          break;
      }

      const setResponse = {
        title: alertTitle,
        message: "",
        subtitle: alertText,
        alertType: "modal",
        onClick: () => setAlertsMutation.setShowAlert(false),
        outsideClick: false,
        cancelButton:{
          text:'',
          onClick:null
        },
        successButton: {
          text:'Entendido',
          onClick:null
        },
        type: errorCode[status],
      };
      setAlertsMutation.setInfoAlert(setResponse);

      ReactGA.event({
        category: "login",
        action: `Error_${status}_form_invite_code`,
      });
    } finally {
      setLoginMutation.setLoadingInvite(false);
    }
  },

  createRegister: async (payload: any) => {
    setLoginMutation.setLoadingSignIn(true);
    try {
      const rest = await restPost({ url: URL_CREATE, data: payload });
      const { id, expirationDateOtp, transacctionIdOtp } = rest.data.data;
      const userData = {
        ...payload,
        clientID: id,
        expirationDateOtp,
        transacctionIdOtp,
      };

      setLoginMutation.setTemporaryData(userData);

      setLoginMutation.setOpenOtp(rest.data.response);

      ReactGA.event({
        category: "login",
        action: "Success_form_register",
      });
    } catch (error) {
      console.error(error);

      ReactGA.event({
        category: "login",
        action: `Error_form_invite_code`,
      });
    } finally {
      setLoginMutation.setLoadingSignIn(false);
    }
  },

  generateTokenAuth: async (payload: string) => {
    const isLoading = processState.getState().isLoading;
    setProcessMutation.setIsLoading({ ...isLoading, [payload]: true });

    try {
      const getAuth = await getTokenAuth({
        url: URL_LOGIN_AUTH,
        auth: USER_TOKEN_AUTH,
      });

      if (getAuth.data.data.token) {
        const token = getAuth.data.data.token;
        setLoginMutation.setTokenAuth(token);
        // setProcessMutation.setProcessMicroblink(true);
        setProcessMutation.setShowProcess('process');
        setLoginMutation.setLoadingPage({status:false});
      }
    } catch (error) {
      console.error(error);
    } finally {
      setProcessMutation.setIsLoading({ ...isLoading, [payload]: false });
    }
  },

  validateOtp: async (payload: any) => {
    setLoginMutation.setLoadingPage({ status: true });
    try {
      const { transacctionIdOtp, pin } = payload;

      const dataToValidateOTP = {
        TransactionId: transacctionIdOtp,
        OtpCode: pin,
      };
      const rest = await restPost({
        url: URL_VALIDATE_OTP,
        data: dataToValidateOTP,
      });
      // const isLogged = {...payload, logged: rest.data.response }
      // setUserMutation.setUser({...payload, pin } )
      if (rest.data.response) {
        loginActions.getState().signTerms(payload);
      }

      ReactGA.event({
        category: "login",
        action: "Success_form_otp",
      });
    } catch (err: any) {
      const message = err.response.data.message.split("Error:")[1].trim();
      const setResponse = {
        title: "Error en el proceso!",
        message: message,
        alertType: "modal",
        subtitle:"",
        cancelButton:{
          text:'Cerrar',
          onClick:null
        },
        successButton: {
            text:'Intentar nuevamente',
            onClick:null
        },
        onClick:()=>{
          setAlertsMutation.setShowAlert(false)
        },
        type: "error",
      };
      setAlertsMutation.setInfoAlert(setResponse);

      ReactGA.event({
        category: "login",
        action: "Error_form_otp",
      });
    } finally {
      setTimeout(() => {
        setLoginMutation.setLoadingPage({ status: false });
      }, 3000);
    }
  },

  reSendOtp: async (payload: any) => {
    setLoginMutation.setLoadingResendOTP(true);

    //Sum OTP Attempts
    setLoginMutation.setOtpAttempts(loginState.getState().otpAttempts + 1);

    try {
      const { transacctionIdOtp, email, language } = payload;

      const dataToRegenerateOTP = {
        TransactionId: transacctionIdOtp,
        EmailTo: email,
        Language: language,
      };

      const errorCode: any = {
        400: "error",
        500: "error",
        200: "success",
      };

      const rest = await restPost({
        url: URL_REGENERATE_OTP,
        data: dataToRegenerateOTP,
      });

      const { expirationDate, transactionId } = rest.data.data;

      const setResponse = {
        title: "Envío correcto!",
        message: "Se generó un nuevo código.",
        alertType: "toast",
        type: errorCode[200],
      };
      setAlertsMutation.setInfoAlert(setResponse);

      const userData = {
        ...payload,
        expirationDateOtp: expirationDate,
        transacctionIdOtp: transactionId,
      };

      setLoginMutation.setTemporaryData(userData);

      ReactGA.event({
        category: "login",
        action: "Success_form_resend_otp",
      });
    } catch (err: any) {
      const message = err.response.data.message.split("Error:")[1].trim();
      const setResponse = {
        title: "Error en el proceso!",
        message: message,
        alertType: "modal",
        subtitle:"",
        cancelButton:{
          text:'Cerrar',
          onClick:null
        },
        successButton: {
            text:'Intentar nuevamente',
            onClick:null
        },
        onClick:()=>{
          setAlertsMutation.setShowAlert(false)
        },
        type: "error",
      };
      setAlertsMutation.setInfoAlert(setResponse);

      ReactGA.event({
        category: "login",
        action: "Error_form_resend_otp",
      });
    } finally {
      setLoginMutation.setLoadingResendOTP(false);
    }
  },

  signTerms: async (payload: any) => {
    try {
      const { clientID, pin } = payload;

      const dataAcceptTerms = {
        UserId: clientID,
        TermId: loginState.getState().infoTerms?.id,
        OtpCode: pin,
      };

      const res = await restPost({
        url: URL_ACCEPTED_TERMS_AND_CONDITIONS,
        data: dataAcceptTerms,
      });
      const isLogged = {
        ...payload,
        logged: res.data.response,
        sessionTime: new Date().getTime() + 86400000,
      };
      setUserMutation.setUser(isLogged);
      // setProcessMutation.setShowModalVideoProcess(true);
      setLoginMutation.setOtpAttempts(0);
    } catch (err: any) {
      const message = err.response.data.message;
      const setResponse = {
        title: "Error en el proceso!",
        message: message,
        subtitle:"",
        cancelButton:{
          text:'Cerrar',
          onClick:null
        },
        successButton: {
            text:'Intentar nuevamente',
            onClick:null
        },
        onClick:()=>{
          setAlertsMutation.setShowAlert(false)
        },
        alertType: "modal",
        type: "error",
      };
      setAlertsMutation.setInfoAlert(setResponse);
    }
  },
}));
