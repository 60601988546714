import React, { useState, useEffect } from 'react'
//Components import
import { Pagination } from './Pagination'
import { TableClassic } from './TableClassic'

//Elements import
import { SectionTitle } from './SectionTitle';

//Store import
import { dashboardState, dashboardMutations, dashboardActions } from "../store/dashboard/_dashboard.main";

export const TableCreatorCode = () => {

    //Store
    const { wrongCounterList, pageNumber, rowsOfPage, totalEvents } = dashboardState()
    const { setPageNumber, setRowsOfPage } = dashboardMutations()
    const { getListWrongCounterCode } = dashboardActions()

    //Data for the Pagination
    const options = [
        { value: '', text: 'Seleccione...' },
        { value: 10, text: "10" },
        { value: 20, text: "20" },
        { value: 30, text: "30" },
    ];

    const behind = () => { 
        let pag = 1  
        if (pageNumber > pag) {
            setPageNumber(pageNumber - 1)
        }
    }  
    
    const following = (payload:any) => {
        if (pageNumber < payload) {
            setPageNumber(pageNumber + 1)
        }
    }

    const handleChange = (payload: any) => {
        const minimumPaging = 10
        const getValue = payload.target.value ? payload.target.value : minimumPaging
        setRowsOfPage(getValue)
    }

    const header:string[] = [
        'Código',
        'Total'
    ]

    const tableRows:any= [];
    wrongCounterList?.forEach((element: any) => {
        delete element.registerCounter
        delete element.codeId
        delete element.groupName
        delete element.activeCode
        
        const tableColums:any= [];
        Object.values(element).forEach((item: any) => {
          tableColums.push(item);
        });
        tableRows.push(tableColums)
    });

    useEffect(()=>{
        let dataToSearch = {
            pageNumber,
            rowsOfPage,
        }
        getListWrongCounterCode(dataToSearch)
    },[pageNumber, rowsOfPage])

  return (
    <>
        <SectionTitle
            title='Listado de códigos incorrectos'
        />
        <div className='relative mx-auto'>
            <Pagination
                textColor="text-gray-700"
                background="bg-slate-200"
                arrowBackColor="bg-slate-100 hover:bg-slate-300 transform active:scale-95 active:border-slate-800"
                arrowFrontColor="bg-slate-100 hover:bg-slate-300 transform active:scale-95 active:border-slate-800"
                rowOptions={ options }
                selectChange={ handleChange }
                pageNumber={ pageNumber }
                rowsOfPage={ rowsOfPage }
                totalRecords={ totalEvents }
                behind={ behind }
                following={ following }
            />
            
            <TableClassic
                clientIdPosition={ 0 }
                data={ tableRows }
                header={ header }
                colorRowEven="bg-white"
                colorRowOdd="bg-gray-100"
            />
        </div>
    </>
  )
}
