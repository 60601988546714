import create from "zustand";

//Store import
import { userMutations } from "../user/_user.main";
import { alertsMutations } from "../alerts/_alerts.main";
import { processMutations } from "../process/_process.main";
import { loginState, loginActions } from "./_login.main";

//Utils import
import { dataDecrypt } from "../../utils/utils.encrypt-decrypt"

//Store Getters
const setUserMutation = userMutations.getState();
const setAlertsMutation = alertsMutations.getState();
const setProcessMutation = processMutations.getState();

export const loginMutations = create((set)=>({

  setOpenOtp: (payload: boolean) => {
    loginState.setState({
      openOtp: payload,
    });
  },

  setTemporaryData: (payload: any) => {
    loginState.setState({
      temporaryData: payload,
    });
  },

  login: () => {
    setUserMutation.setStatus('disabled')
  },

  logout: () => {
    setUserMutation.logoutUser(null);
    loginMutations.getState().setTemporaryData(null)
    /* localStorage.removeItem("videoSeen")
    localStorage.removeItem("messageSeen") */
    localStorage.clear()

    loginState.setState({
      showLogin:false
    });

    loginState.setState({
      openOtp:false
    });
    
    // setProcessMutation.setShowProcess('home');
    // window.location.href = "/";
  },

  setLoadingInvite: (payload: boolean) => {
    loginState.setState({
      loadingInvite: payload,
    });
  },
  
  setShowLogin: (payload: boolean) => {
    loginState.setState({
      showLogin: payload,
    });
  },
  
  setShowLoginModal: (payload: boolean) => {
    loginState.setState({
      showLoginModal: payload,
    });
  },
  
  setLoadingSignIn: (payload: boolean) => {
    loginState.setState({
      loadingSignIn: payload,
    });
  },
  
  setLoadingResendOTP: (payload: boolean) => {
    loginState.setState({
      loadingResendOTP: payload,
    });
  },
  
  setInviteCode: (payload: string) => {
    loginState.setState({
      inviteCode: payload,
    });
  },
  
  setTokenAuth: (payload: string) => {
    loginState.setState({
      tokenAuth: payload,
    });
  },
  
  setOtpAttempts: (payload: number) => {
    loginState.setState({
      otpAttempts: payload,
    });
  },
  
  setInfoTerms: (payload: any) => {
    loginState.setState({
      infoTerms: payload,
    });
  },

  submitLogin: (payload: any) =>{

    const setLoginActions = loginActions.getState();
    const errorCode:any = {
      400: "error",
      500: "error",
      200: "success"
    }
    const { nickName,email,cellphone } = payload;
    if (nickName && email && cellphone) {
      setLoginActions.createRegister(payload);
    } else {
      const setResponse = {
        title: "Formulario incompleto!",
        message: "Es necesario completar todos los campos.",
        subtitle:"",
        cancelButton:{
          text:'Cerrar',
          onClick:null
        },
        successButton: {
            text:'Intentar nuevamente',
            onClick:null
        },
        onClick:()=>{
          setAlertsMutation.setShowAlert(false)
        },
        alertType: "modal",
        type: errorCode[500],
      }
      setAlertsMutation.setInfoAlert(setResponse);      
    }
  },

  maxUseFromOTP: () =>{
    const setResponse = {
      title: "Intentos máximos alcanzados!",
      message: "Ha superado la cantidad de intentos permitidos. Por favor inicie el proceso nuevamente...",
      subtitle:"",
      cancelButton:{
        text:'Cerrar',
        onClick:null
      },
      successButton: {
          text:'Intentar nuevamente',
          onClick:null
      },
      onClick:()=>{
        setAlertsMutation.setShowAlert(false)
      },
      alertType: "modal",
      type: "error",
    }
    // loginMutations.getState().setOpenOtp(false);
    setAlertsMutation.setInfoAlert(setResponse);
    loginMutations.getState().setOtpAttempts(0)
    loginMutations.getState().logout()

  },

  endSessionAndNotify: () => {
    const setResponse = {
      title: "El tiempo de espera",
      message: "ha excedido el límite",
      alertType: "modal",
      subtitle:"",
      cancelButton:{
        text:'Cerrar',
        onClick:null
      },
      successButton: {
          text:'Intentar nuevamente',
          onClick:null
      },
      onClick:()=>{
        setAlertsMutation.setShowAlert(false)
      },
      type: "warning",
    }
    // loginMutations.getState().setOpenOtp(false);
    setAlertsMutation.setInfoAlert(setResponse);
    loginMutations.getState().setOtpAttempts(0)
    loginMutations.getState().logout()
  },

  setLoadingPage: (payload:{status:boolean;type?:'icon'|'text'|'circle';}) => {
    loginState.setState({
      loadingPage: payload,
    });
  },
}));