import React from "react";
import { RoutesMain } from "./routes/_routes.main";

//Components import
import { AlertMessageType } from "./elements/alerts/AlertMessageType";
import { ProcessSuccess } from "./components/process/ProcessSuccess";
import { LoaderPageType } from "./elements/_elements.main";
import { LoginModalPoll } from "./components/login/LoginModalPoll";

//Store import
import { alertsMutations, alertsState } from "./store/alerts/_alerts.main";
import { processState, processMutations } from "./store/process/_process.main";
import { loginState } from "./store/login/_login.main";

//Assets import
import "../src/assets/styles/styles.general.css";
import "../src/assets/styles/styles.native.css";
import "../src/assets/styles/styles.scroll.css";

// Ga import
import ReactGA from "react-ga4";

ReactGA.initialize("G-VF2C1R1JDS");

function App() {
  const { infoAlert, showAlert } = alertsState();
  const { infoProcess, showModalSuccess } = processState();
  const { loadingPage, showLoginModal } = loginState();

  return (
    <>
      <div className="cover--absolute overflow-hidden bg-gradient-to-b z-0 font-poppins">
        <div className="cover--absolute z-20">
          <RoutesMain />
          {showLoginModal && <LoginModalPoll />}
          {showAlert && (
            <AlertMessageType
              content={infoAlert}
              onClick={infoAlert?.onClick}
              outsideClick={infoAlert?.outsideClick}
            />
          )}
          {showModalSuccess && (
            <ProcessSuccess
              title={infoProcess?.title}
              message={infoProcess?.message}
              subtitle={infoProcess?.subtitle}
              buttonText={infoProcess?.buttonText}
              rotate={infoAlert?.rotate}
              onClick={infoProcess?.onClick}
            />
          )}
        </div>
        {loadingPage.status && <LoaderPageType type={loadingPage.type} />}
      </div>
    </>
  );
}

export default App;
