import React, { useEffect, useRef } from 'react'

//External libraries import
// import "access-cam";

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'identi5id-face': IFIdenti5IDFace;
        }
    }
}

interface IFIdenti5IDFace extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> {
    primary: string,
    secondary: string,
    client:number;
    token_auth: null | string;
    token:string;
    process:string;
    event:string;
    avatar_background:string;
    document_face_image:string;
}

interface IFProcess{
    primary:string;
    secondary:string;
    client:number;
    token_auth:null | string;
    token:string;
    process:string;
    event:string;
    avatar_background:string;
    document_face_image:string;
    loadedComponent:(e:any)=>void
    responseClient:(e:any)=>void
}

const defaultProps = {
    primary:'#30D1FC',
    secondary:'',
    client:null,
    token_auth:'',
    token:'',
    process:'',
    event:'',
    avatar_background:'',
    document_face_image:'',
    loadedComponent:null,
    responseClient:null
}

export const Identi5IDFace = (props:IFProcess) => {

    const identi5idFace = useRef<HTMLElement>(null);
    
    useEffect(() => {
        // CognoxConnection()
        identi5idFace.current && document.addEventListener("identi5IDLoaded", props.loadedComponent);
        identi5idFace.current && document.addEventListener("identi5IDResponse", props.responseClient);

        return () => {
            document.removeEventListener("identi5IDLoaded", props.loadedComponent);
            document.removeEventListener("identi5IDResponse", props.responseClient);
        };
    },[]);
       
    return (
        <>
            <identi5id-face
                ref={ identi5idFace }
                primary={ props.primary }
                secondary={ props.secondary }
                client={ props.client }
                token_auth={props.token_auth}
                token={ props.token }
                process={ props.process }
                event={ props.event }
                avatar_background={ props.avatar_background }
                document_face_image={ props.document_face_image }
            ></identi5id-face>
        </>
    )
}

Identi5IDFace.defaultProps = defaultProps