import React, { useEffect } from "react";
import { Link } from "react-router-dom";

//Components import
import { HomeProcessVideoModal } from "./HomeProcessVideoModal"

//Elements import
import {
  ButtonClassic,
  ButtonIcon,
  ButtonLink,
} from "../../elements/_elements.main";

//Store import
import { userState } from "../../store/user/_user.main"
import { processState, processMutations } from "../../store/process/_process.main"
import { loginMutations } from "../../store/login/_login.main";

//Utils import
import { dataDecrypt } from "../../utils/utils.encrypt-decrypt"

//Assets import
import background from '../../assets/img/backgroundDemo.png'
import logoIdenti5IDFull from "../../assets/img/logoIdenti5ID-full.svg";
import logoAppinITFull from "../../assets/img/logoAppinit-full.svg";
import home from "../../assets/img/home.jpg";

import { faChevronRight } from "@fortawesome/free-solid-svg-icons";


export const HomeSection = () => {
  const { logout } = loginMutations()

  const { showModalVideoProcess } = processState()
  const { setShowModalVideoProcess, setShowProcess } = processMutations()

  const { user } = userState();  
  const name = dataDecrypt(user)?.nickName
  
  const getVideoStatus = localStorage.getItem("videoSeen")
  const showVideoProcess = showModalVideoProcess && !JSON.parse(getVideoStatus ?? 'false')

  const handleVideoModal = () => {
    setShowModalVideoProcess(true)
  }

  return (
    <div className="relative h-full overflow-hidden">
      <div className='absolute sm:hidden grid justify-center items-center top-5 left-5 z-30'>
        <a href="https://www.appinit.co/" target='_blank' rel="noopener noreferrer">
          <p className='text-sm text-white'>Powered by</p>
          <img src={ logoAppinITFull } alt="logo appinIT" className="mx-auto mb-4 object-cover w-20" />
        </a>
      </div>
      <div className="absolute block ml-auto top-5 right-5 z-30">
        <ButtonClassic
          height="h-9"
          width="w-28"
          color="secondary"
          text="Salir"
          onClick={() => logout()}
          classes="ml-auto font-light"
        />
      </div>
      <div className="absolute grid grid-cols-2 h-full w-full bottom-0 z-10">
        <div className="shadow-4xl shadow-white bg-white col-span-2 sm:col-span-1 order-2 sm:order-1 z-20">
          {/* <img
            src={ background }
            alt="" 
            className='h-full w-full object-cover min-w-[700px]'/> */}
        </div>
        <img
          src={ home }
          alt={`Bienvenido ${name}`}
          className="object-cover sm:min-w-[500px] h-[50vh] sm:h-full w-full col-span-2 sm:col-span-1 order-1 sm:order-2"
        />
      </div>
      <div className="cover--relative flex justify-center sm:justify-start items-end sm:items-center overflow-y-auto sm:p-10">
        <div className="bg-white shadow-3xl shadow-white sm:shadow-transparent sm:p-16 px-8 md:p-0 sm:max-w-xs lg:max-w-2xl z-10 sm:bg-transparent mb-12">
          <header className="mb-3 sm:mb-7">
            <div>
              <a
                href="https://www.identi5id.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={logoIdenti5IDFull}
                  alt="logo Identi5ID"
                  className="mb-7 mx-auto sm:ml-0 object-cover w-52"
                />
              </a>
            </div>
            <h4 className="text-main font-bold text-xl mb-2">Hola, { name }</h4>
            <h3 className="text-left text-primary font-regular text-2xl sm:text-4xl mb-4 leading-6">
              Bienvenido <span className="font-bold block sm:inline text-left">a un lugar seguro</span>
            </h3>
            <p className="text-gray-500 text-justify text-sm leading-5">
            para probar nuestra solución de <span className="font-bold">tecnología biométrica facial</span> debes realizar las
            siguientes acciones en dos etapas y así puedas experimentar lo que vivirán tus
            clientes con nuestro desarrollo, el cual puede ir integrado a los plataformas de tu
            empresa, agregando una fuente de <span className="font-bold">seguridad y confiabilidad</span> en procesos de
            validación y reconocimiento. 
            </p>
          </header>
          <footer>
            <h3 className="sm:text-left text-primary font-bold text-xl mb-3 leading-5">
              En solo dos etapas estarás más seguro
            </h3>
            
            <div className="flex items-center mt-3">
              <ButtonLink
                classes="font-bold text-xs text-left text-main font-bold"
                text="¿Quieres ver el video de demostración?"
                onClick={ handleVideoModal }
              />
              <ButtonIcon width="w-5" height="h-5" classes="p-1 bg-main ml-1 text-white" icon={ faChevronRight } onClick={ handleVideoModal } />
            </div>

            <ButtonClassic
              width="w-40"
              type="submit"
              color="secondary"
              text="Hacer prueba"
              classes="block mt-5"
              onClick={ ()=> setShowProcess("steps") }
            />
          </footer>
        </div>
      </div>
      {showVideoProcess && <HomeProcessVideoModal/>}
    </div>
  );
};
