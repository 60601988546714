import React from "react";

//Assets import
import iconLoading from "../../assets/icons/loading.svg";

//Props
interface IFButtonLink {
  text: string|React.ReactElement;
  classes: string;
  loading: boolean;
  onChange(e: any): void;
  onInput(e: any): void;
  onBlur(e: any): void;
  onClick: () => void;
}
//valor x default
const defaultProps = {
  text: "ingresa texto",
  classes: "",
  loading: false,
  onChange: null,
  onClick: null,
  onInput: null,
  onBlur: null,
};
export const ButtonLink = (props: IFButtonLink) => {
  return (
  <>
    <button 
      onClick={props.onClick}
      className={`${props.classes}`}
    >{!props.loading ? (
      <span>{props.text}</span>
    ) : (
      <img
        src={ iconLoading }
        alt="loading"
        className="btn-classic__loader"
      />
    )}
    </button>
  </>);
};

ButtonLink.defaultProps = defaultProps;