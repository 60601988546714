import React from 'react'

// Store imports
import { processMutations } from "../../store/process/_process.main";

// Elements import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonIcon, ModalClassic } from '../../elements/_elements.main'

// Assets import
import { faChevronRight, faClose } from "@fortawesome/free-solid-svg-icons";
import logoIdenti5IDFull from "../../assets/img/logoIdenti5ID-full.svg";
import success from "../../assets/icons/success.svg";

interface IFProcessSuccess{
    title:string;
    message:string;
    subtitle:string;
    buttonText:string;
    rotate?:'yes'|'no';
    onClick:()=>void;
}

const defaultProps = {
    title:'',
    message: '',
    subtitle:'',
    onClick: null,
    buttonText: '',
}

export const ProcessSuccess = (props:IFProcessSuccess) => {

  return <>
        <ModalClassic size="medium" classes="p-5">
            <div className={`${props.rotate === 'yes' ? '-rotate-90':''} bg-white p-5 rounded-xl`}>
                <ButtonIcon
                    icon={ faClose }
                    onClick={()=> props.onClick() }
                    color=""
                    width="w-7"
                    height="h-7"
                    classes="identi5ID-alert-modal__close"
                />
                <div className="border-2 border-slate-400 rounded-xl p-5">
                    <div>
                        <span className={`identi5ID-alert-modal__icon identi5ID-alert-modal__icon--pulse bg-green-600`}></span>
                        <span className={`identi5ID-alert-modal__icon relative bg-green-600`}>
                        <img src={ success } alt={`Icon Sucess`} className={`bg-green-400 identi5ID-alert-modal__icon identi5ID-alert-modal__icon--shadow`} />
                        </span>
                    </div>
                    <div dangerouslySetInnerHTML={{__html: props.title }}></div>
                    <div dangerouslySetInnerHTML={{__html: props.message }}></div>
                    <div dangerouslySetInnerHTML={{__html: props.subtitle }}></div>
                    <button onClick={ props.onClick } className="text-secondary border-2 mt-5 border-secondary rounded-full mx-auto cursor-pointer flex justify-center items-center py-2 px-4">
                        <p className="mr-1 font-light text-base">{ props.buttonText }</p>
                        <FontAwesomeIcon
                            className={`bg-secondary w-4 h-w-4 text-white rounded-full p-1`}
                            icon={ faChevronRight }
                        />
                    </button>
                    <img src={ logoIdenti5IDFull} alt="Logo Identi5ID" className='object-cover w-44 mt-5 mx-auto' />
                </div>
            </div>
        </ModalClassic>
    </>
}

ProcessSuccess.defaultProps = defaultProps