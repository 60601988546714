export const USER_TOKEN_AUTH = {
    userName: process.env.REACT_APP_USER_AUTH,
    password: process.env.REACT_APP_PASS_AUTH,
}

const ENDPOINT = process.env.REACT_APP_ENDPOINT_API

/*INVITATION CODE */
export const URL_VALIDATE_CODE = `${ENDPOINT}/InvitationCode`;
export const URL_CREATE_CODE = `${ENDPOINT}/InvitationCode/Create`;
export const URL_GET_INVITATION_CODE_DETAILS = `${ENDPOINT}/InvitationCode/GetById`;
export const URL_GET_USAGE_COUNTER_CODE = `${ENDPOINT}/UseByCode/UsageCounter`;
export const URL_GET_WRONG_COUNTER_CODE = `${ENDPOINT}/UseByCode/WrongCounter`;

export const URL_CREATE = `${ENDPOINT}/User`;

/* API OTP*/
export const URL_VALIDATE_OTP = `${ENDPOINT}/Otp/Validate`;
export const URL_REGENERATE_OTP = `${ENDPOINT}/Otp/Regenerate`;

/* API AUTH TOKEN BEARER */
export const URL_LOGIN_AUTH = `${process.env.REACT_APP_ENDPOINT_AUTH}/ApplicationAuth`;

/* TERMS AND CONDITIONS */
export const URL_GET_TERMS_AND_CONDITIONS = `${ENDPOINT}/Term`;
export const URL_ACCEPTED_TERMS_AND_CONDITIONS = `${ENDPOINT}/UserTerm`;

/* LOGIN CREATOR CODE */
export const URL_LOGIN_CREATOR_CODE = `${ENDPOINT}/ApplicationAuth/Login`;

//RefreshToken
export const URL_REFRESH_TOKEN = `${ENDPOINT}/ApplicationAuth/RefreshToken`;
export const SAVE_AUTH_LOCAL_STORAGE = 'auth';