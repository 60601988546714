import create from "zustand";

//Store import
import { dashboardState } from "./_dashboard.main";

export const dashboardMutations = create((set)=>({

  setUsageCounterList: (payload:[]) => {
    dashboardState.setState({
      usageCounterList: payload,
    });
  },
  setWrongCounterList: (payload:[]) => {
    dashboardState.setState({
      wrongCounterList: payload,
    });
  },
  setTotalEvents: (payload: any) => {
    dashboardState.setState({
      totalEvents: payload,
     });
  },
  setPageNumber: (payload: any) => {
    dashboardState.setState({
      pageNumber: payload,
    });
  },
  setRowsOfPage: (payload: any) => {
    dashboardState.setState({
      rowsOfPage: payload,
    });
  },
  setNewCode: (payload: any) => {
    dashboardState.setState({
      newCode: payload,
    });
  },
  setStatusCard: (payload:boolean) => {
    dashboardState.setState({
      statusCard: payload,
    });
  },
  setLoadingNewCode: (payload:boolean) => {
    dashboardState.setState({
      loadingNewCode: payload,
    });
  },
  setLoadingEditCode: (payload:boolean) => {
    dashboardState.setState({
      loadingEditCode: payload,
    });
  },
  setShowModalEdit: (payload:boolean) => {
    dashboardState.setState({
      showModalEdit: payload,
    });
  },
  setSlideInAdd: (payload:boolean) => {
    dashboardState.setState({
      showSlideInAdd: payload,
    });
  },
  setCardInfo: (payload:{
    code:string;
    status:boolean;
    id:number;
    group:string;
    registerCounter:number;
    useCounter:number;
  }) => {
    dashboardState.setState({
      cardInfo: payload,
    });
  },
}));