import React, { useState } from 'react'

//Elements import
import { ButtonClassic } from "../_elements.main";

//Styles import
import iconLoading from "../../assets/icons/loading.svg";
import '../../assets/styles/elements/elements.cards.css'

//Assets import
import iconIdenti5ID from "../../assets/img/iconoIdenti5ID-full.svg"

interface IFCardStatus {
    image:string;
    status: "enabled" | "disabled";
    title:string;
    buttonText:string;
    subtitle:string;
    description:string;
    showPulse:boolean;
    isLoading:boolean;
    width:string;
    height:string;
    classes:string;
    onClick:(e:any)=>void;
}

const defaultProps = {
    image:'',
    status: "disabled",
    title:'Title',
    buttonText:'Mensaje de botón',
    subtitle:'Subtitle',
    description:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore sequi temporibus nemo labore nihil assumenda consectetur debitis ea a ut velit unde, natus adipisci optio libero excepturi possimus sunt voluptatum!',
    showPulse: false,
    isLoading: false,
    width:'w-64',
    height:'',
    classes:'',
    onClick: null
}

export const CardStatus = (props:IFCardStatus) => {

    const cardType:any = {
        enabled:{
            from: 'from-gray-100',
            to: 'to-gray-100',
            border: true,
            borderColor: 'border-sky-500',
            pulse: true,
            title: 'text-main',
            subtitle: 'text-white',
            description:'text-[#333]'
        },
        disabled:{
            from: 'from-white',
            to: 'to-white',
            border: false,
            borderColor: 'border-white',
            pulse: false,
            title: 'text-main',
            subtitle: 'text-white',
            description:'text-[#333]'
        }
    }

    const borderStatus = ()=>{
        return props.showPulse && cardType[props.status].borderColor
    }

    const color = (payload:any)=>{
        return cardType[props.status][payload]
    }

    const size = (payload:any) => {
        const pixel = payload.split("-")
        const sizes:any = {
            w: 'w-'+pixel[1],
            h: 'h-'+pixel[1],
        }
        return sizes[pixel[0]]
    }
    
    const showBorder = cardType[props.status].border

    const handleClick = props.status === 'enabled' ? props.onClick : () => {}

  return (
    <>
        <div className={`card-status ${props.status === 'enabled' ? "hover:scale-105" : ''} ${props.classes}`} onClick={ handleClick }>
            {(props.showPulse && !props.isLoading) &&
                <span className="card-status__pulse"></span>
            }
            <div className={`card-status__content ${props.status === 'enabled' ? 'cursor-pointer': ''} ${borderStatus()} ${color('from')} ${color('to')} ${size(props.width)}`}>
                {
                    props.isLoading &&
                    <div className='flex items-center justify-center absolute top-0 bottom-0 right-0 left-0'>
                        <img
                            src={ iconLoading }
                            alt="loading"
                            className="card-status__loader-icon"
                        />
                        <div className="card-status__loader"></div>
                    </div>
                }
                <div>
                    <h3 className={`card-status__title ${color('title')}`}>{ props.title }</h3>
                    <span className={`card-status__subtitle ${color('subtitle')}`}>
                        { props.subtitle }
                        <img 
                            src={ iconIdenti5ID } 
                            alt="" className='h-8 ml-auto relative right-14 object-cover object-top'
                        />
                    </span>
                    <p className={`card-status__description ${color('description')}`}>{ props.description }</p>
                    <img 
                        src={ props.image } 
                        alt="" className='card-status__image'
                    />
                    <ButtonClassic
                        height="h-11"
                        width="w-auto"
                        color="secondary"
                        text={ props.buttonText }
                        onClick={ ()=> handleClick }
                        classes="mr-auto font-light"
                    />
                </div>
            </div>
        </div>
    </>
  )
}

CardStatus.defaultProps = defaultProps