import React, { useState } from 'react'
import { Link } from 'react-router-dom';

//Elements import
import { FormClassic, InputForm, ButtonClassic, InputPassword } from "../../../elements/_elements.main";

//Assets import
import logoAppinITFull from "../../../assets/img/logoAppinit.svg";
import facialBack from "../../../assets/img/facialBack.jpg";
import securityBack from "../../../assets/img/securityBack.png";

//Store import
import { loginState, loginMutations, loginActions } from "../store/login/_login.main";

export const LoginFormCreatorCode = () => {

  const [enphasisImage, setEnphasisImage] = useState(facialBack)

  const { login } = loginActions()
  const { userInformation, loadingLoginCreatorCode } = loginState()
  const { setUserInformation } = loginMutations()

  const imageType:any = {
    username: facialBack,
    password: securityBack
  }

  const handleSubmit = () => {
    login()
  };

  const handleImageChange = (payload:any) => {
    const setType:string = payload.target.name
    setEnphasisImage(imageType[setType])
  }

  return (
    <article className='bg-white w-80 max-w-[320px] min-h-[400px] h-[477px] border-2 shadow-xl shadow-slate-400 rounded-xl overflow-hidden'>
      <header className='relative z-10'>
        <img className='scale-150 transition-all duration-300 object-cover w-full h-48' src={ enphasisImage } alt="" />
        <div className='absolute top-3 right-3 grid justify-center items-center'>
          <Link to="https://www.appinit.co/" target='_blank'>
            <p className='text-sm text-white'>Powered by</p>
            <img src={ logoAppinITFull } alt="logo appinIT" className="mx-auto mb-4 object-cover w-20" />
          </Link>
        </div>
      </header>
      <footer className='relative z-20 top-0 bg-white shadow-3xl shadow-white px-5'>
        <h3 className='mb-5 text-purple-900 font-medium bottom-7 text-lg leading-4 text-center'>Por favor ingresa los datos solicitados a continuación</h3>
        <div className="">
          <FormClassic classes='' autoComplete='off' onSubmit={ handleSubmit }>
            <InputForm
              type="text"
              placeholder="Usuario"
              maxLength={ 40 }
              minLength={5}
              value={ userInformation.username }
              width='w-full'
              classes="my-3"
              hint="* Ingresa tu nombre completo"
              onFocus={ handleImageChange }
              name='username'
              onChange={(e:any) =>
                setUserInformation({...userInformation, username: e.target.value})
              }
            />

            <InputPassword
              value={ userInformation.password }
              placeholder="Contraseña"
              width='w-full'
              name="password"
              onFocus={ handleImageChange }
              onChange={(e:any) =>
                setUserInformation({...userInformation, password: e.target.value})
              }
            />
            <ButtonClassic
              loading={ loadingLoginCreatorCode }
              type="submit"
              text="Ingresar"
              width='w-full'
              classes="block my-7 bg-yellow-600 rounded-3xl"
            />
          </FormClassic>
        </div>
      </footer>
    </article>
  )
}
