import React from 'react'

import { Routes, Route } from "react-router-dom";

import { RoutesPublic } from "./routes.public";
import { RoutesPrivate } from "./routes.private";

//Store Import
import { loginState } from "../store/login/_login.main";

//Utils import
import { dataDecrypt } from '../../../utils/utils.encrypt-decrypt';
import { SAVE_AUTH_LOCAL_STORAGE } from "../../../app.tickets"

export const RoutesCreatorCodes = () => {

  const { isLogged } = loginState()
  
  const getAuth = dataDecrypt(localStorage.getItem(SAVE_AUTH_LOCAL_STORAGE));

  const auth = isLogged || getAuth?.isLogged

  return (
    <Routes>
      <Route
        path="/*"
        element={!auth ? <RoutesPublic /> : <RoutesPrivate />}
      />
    </Routes>
  )
}
