//Axios import
import axios from "axios";

//Utils import
import { axiosInterceptor } from "./utils.interceptors";
import { dataEncrypt } from "../utils/utils.encrypt-decrypt";

export const restPost = async (payload: any) => {

  const data = await dataEncrypt(payload.data);
  const query = JSON.stringify({ dataEncrypt: data });

  return axiosInterceptor.post(payload.url, query, {
    headers: {
      "Content-Type": "application/json",
    },
  });

};

export const restPostNotEncrypted = async (payload: any) => {

  const query = JSON.stringify(payload.data);

  return axiosInterceptor.post(payload.url, query, {
    headers: {
      "Content-Type": "application/json",
    },
  });

};

export const restGet = async (payload: any) => {
  return axiosInterceptor.get(payload.url, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const restPut = async (payload: any) => {
 
  const data = await dataEncrypt(payload.data);  
  const query = JSON.stringify({ dataEncrypt: data });

  return axiosInterceptor.put(payload.url, query, {
    headers: {
      "Content-Type": "application/json",
    },
  });

};

export const getTokenAuth = async (payload: any) => {

  const query = JSON.stringify(payload.auth);

  return axios.post(payload.url, query, {
    headers: {
      "Content-Type": "application/json",
    },
  });

};
