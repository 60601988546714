import create from "zustand";

//Types import
import { IFDashboard } from "../../types/types.dashboard"

export const dashboardState = create<IFDashboard>(() => ({
    usageCounterList:[],
    wrongCounterList:[],
    totalEvents: 0,
    pageNumber: 1,
    rowsOfPage: 10,
    newCode:{
        code:'',
        group:''
    },
    cardInfo:{
        code:'',
        status:false,
        id:0,
        group:'',
        registerCounter:0,
        useCounter:0
    },
    statusCard:false,
    loadingNewCode:false,
    loadingEditCode:false,
    showModalEdit:false,
    showSlideInAdd:false
}));
