import React from 'react'

interface IFLoaderCircle{
  percentage?:string;
}
const defaultProps = {
  
}
export const LoaderCircle = (props:IFLoaderCircle) => {
  return (
    <div className="loader-circle">
        <div className="loader-circle__wrapper">
          <div className="loader-circle__percentage">{ props.percentage }</div>
          <span className="loader-circle__dots"></span>
        </div>
    </div>
  )
}
