import React,{ useEffect } from 'react'

//Components import
import { CardAddAndEditCreatorCode } from './CardAddAndEditCreatorCode'
import { SectionTitle } from './SectionTitle';

//Elements import
import { ButtonIcon, ModalClassic } from '../../../elements/_elements.main';

//Store import
import { dashboardState, dashboardMutations, dashboardActions } from '../store/dashboard/_dashboard.main';

// Assets import
import { faCheck, faClose } from "@fortawesome/free-solid-svg-icons";

export const DashboardModalEdit = () => {

    //Store
    const { statusCard, loadingEditCode, showModalEdit, cardInfo } = dashboardState()
    const { setStatusCard, setCardInfo, setShowModalEdit } = dashboardMutations()
    const { updateCode } = dashboardActions()

    const handleClick = () => {
        const setUpdate = {
            id: cardInfo.id,
            customerGroupName: cardInfo.group,
            code:cardInfo.code,
            active:cardInfo.status
        }
        updateCode(setUpdate)
    }

    const handleChange = (payload:any) => {
        const { name, value } = payload.target
        setCardInfo({...cardInfo, [name]:value})
    }
    
    const handleChangeSwitch = (payload:any) => {
        setCardInfo({...cardInfo, status: payload.target.checked})
    }
    
    const handleClose = () => {
        setShowModalEdit(false)
    }

    const validateLenght = (payload:string):boolean => {
        const minCharacters = 4
        return payload.length >= minCharacters
    }

    useEffect(()=>{
        const getStatus = validateLenght(cardInfo.code) && validateLenght(cardInfo.group)
        setStatusCard(getStatus)
    },[cardInfo.code,cardInfo.group])

  return (
    <div>
        {
            showModalEdit && 
            <ModalClassic size="small" classes="py-7 sm:px-8 flex flex-col justify-center items-center bg-transparent text-white">
                <ButtonIcon
                    icon={ faClose }
                    onClick={ handleClose }
                    color="error"
                    width="w-7"
                    height="h-7"
                    classes="modal__btn-close text-white"
                />
                <SectionTitle
                    title='Editar código'
                />
                <CardAddAndEditCreatorCode
                    index={ cardInfo.id }
                    type='edit'
                    code={ cardInfo.code }
                    group={ cardInfo.group }
                    registerCounter={ cardInfo.registerCounter }
                    useCounter={ cardInfo.useCounter }
                    icon={ faCheck }
                    onClick={ handleClick }
                    onChange={ handleChange }
                    onChangeSwitch={ handleChangeSwitch }
                    status={ cardInfo.status }
                    statusCard={ statusCard }
                    isLoading={ loadingEditCode }
                />
            </ModalClassic>
        }
    </div>
  )
}
