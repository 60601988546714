import React from 'react'

//Elements import
import { ButtonIcon } from "../elements/_elements.main";
import { IconProp } from '@fortawesome/fontawesome-svg-core';

//Assets import
import logoIdenti5IDFull from "../assets/img/logoIdenti5ID.svg";
import { faClose } from "@fortawesome/free-solid-svg-icons";

interface IFFirstLayout{
  content:React.ReactElement;
  sidebar:React.ReactElement;
  icon:IconProp|string;
  onClick: () => void;
}

const defaultProps = {
  content: null,
  sidebar: null,
  icon: faClose,
  onClick: null
}

export const FirstLayout = (props:IFFirstLayout) => {

  return (
    <div className='grid grid-cols-12'>
      <header className='col-span-12 bg-violet-900 w-full h-14 flex justify-around text-center items-center relative'>
        <img src={ logoIdenti5IDFull } className='w-32' alt="" />
        <ButtonIcon
          height='h-7'
          width='w-7'
          icon={ props.icon }
          onClick={ props.onClick }
          classes={`font-light bg-error absolute right-3 text-white`}
        />
      </header>
      <div className='col-span-12 lg:col-span-8 p-5 sm:p-8 border-r-2 border-slate-200'>{ props.content }</div>
      <div className='col-span-12 hidden lg:col-span-4 sm:flex justify-center items-start'>{ props.sidebar }</div>
    </div>
  )
}

FirstLayout.defaultProps = defaultProps
