import create from "zustand";

//Store import
import { loginState } from "./_login.main";

//Utils import
import { dataDecrypt } from "../../../../utils/utils.encrypt-decrypt"
import { removeAllItemsLocal } from "../../../../utils/utils.storage";

export const loginMutations = create((set)=>({

  setLoadingLoginCreatorCode: (payload: boolean) => {
    loginState.setState({
      loadingLoginCreatorCode: payload,
    });
  },
  
  setUserInformation: (payload:{username:string;password:string}) => {
    loginState.setState({
      userInformation: payload,
    });
  },

  setIsLogged: (payload:boolean) => {
    loginState.setState({
      isLogged: payload,
    });
  },

  setShowModalLogout: (payload:boolean) => {
    loginState.setState({
      showModalLogout: payload,
    });
  },

  logout: () => {
    removeAllItemsLocal()

    loginState.setState({
      isLogged:false
    });
    
    loginState.setState({
      showModalLogout:false
    });
  },
}));