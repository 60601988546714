
import React from 'react';
import iconLoading from "../../assets/icons/loading.svg";

// Styles import
import "../../assets/styles/elements/elements.buttons.css";

//Props
interface IFButtonClassic {
  type: "button" | "submit";
  text: string;
  color: string;
  height: string;
  width: string;
  classes: string;
  disabled: boolean;
  loading: boolean;
  onClick: () => void;
}

const defaultProps = {
  type: "button",
  text: "Button text",
  color: "primary",
  height: "h-12",
  width: "w-full md:w-72",
  classes: "",
  disabled: false,
  loading: false,
  onClick: null,
};

export const ButtonClassic = (props: IFButtonClassic) => {

  const color = () => {
      
    const options: any = {
      primary: "btn-classic--primary",
      secondary: "btn-classic--secondary",
      error: "btn-classic--error",
    };

    return options[props.color];
  };

  return (
    <>
      <button
        type={props.type}
        disabled={props.disabled || props.loading}
        className={`btn-classic ${color()} ${props.width} ${props.height} ${props.classes}`}
        onClick={props.onClick}
      >
      {!props.loading ? (
        <span>{props.text}</span>
      ) : (
        <img
          src={iconLoading}
          alt="loading"
          className="btn-classic__loader"
        />
      )}
       
      </button>
    </>
  );
};

ButtonClassic.defaultProps = defaultProps;
