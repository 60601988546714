//Router import
import { createBrowserRouter, RouterProvider } from "react-router-dom";

// Routes
import { RoutesApp } from "./routes.app";
import { RoutesCreatorCodes } from "../modules/creatorcodes/routes/routes.creatorcodes";

const router = createBrowserRouter([
	{
		path: '/*',
		element: <RoutesApp />,
	},
	{
		path: 'admin/*',
		element: <RoutesCreatorCodes />,
	},
]);

export const RoutesMain = () => {
  return (
    <RouterProvider router={router} />
  );
};
