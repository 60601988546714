import React from 'react'

//Components import
import { CardSlideCreatorCode } from "../components/CardSlideCreatorCode";
import { TableCreatorCode } from '../components/TableCreatorCode';
import { CardCodeMostUsed } from '../components/CardCodeMostUsed';
import { CardAddCreatorCode } from "../components/CardAddCreatorCode";
import { DashboardModalEdit } from '../components/DashboardModalEdit';
import { DashboardSlideAdd } from '../components/DashboardSlideAdd';

//Elements import
import { ModalClassic, ButtonClassic } from '../../../elements/_elements.main';

//Store import
import { loginState, loginMutations } from "../store/login/_login.main";

//Layout import
import { FirstLayout } from "../../../layouts/_main.layout";

export const DashboardCreatorCode = () => {

  const { showModalLogout } = loginState()
  const { logout, setShowModalLogout } = loginMutations()

  const handleClose = () => {
    setShowModalLogout(!showModalLogout)
  }

  return (
    <div className='bg-white'>
      <FirstLayout
        onClick={ handleClose }
        content={
          <>
            <CardSlideCreatorCode/>
            <TableCreatorCode/>
          </>
        }
        sidebar={
          <div className='p-8 w-full flex flex-col justify-between gap-10'>
            <CardCodeMostUsed/>
            <CardAddCreatorCode/>
          </div>
         }
      />
      <DashboardModalEdit/>
      <DashboardSlideAdd/>
      {
        showModalLogout &&
        <ModalClassic size="small" classes="py-7 px-8">
            <h3 className='my-8'>¿Desea finalizar la sesión?</h3>
            <div className='flex justify-around'>
              <ButtonClassic
                height="h-11"
                width="w-24"
                text="Aceptar"
                onClick={ logout }
                classes={`font-light`}
              />
              <ButtonClassic
                height="h-11"
                width="w-24"
                text="Cancelar"
                onClick={ handleClose }
                classes={`font-light bg-error`}
              />
            </div>
        </ModalClassic>
      }
    </div>
  )
}
