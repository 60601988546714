import { useState, useRef, useEffect } from "react";
//Utils imports,
import { focusReturn } from "../../utils/utils.form";
// Icons import
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
// Styles import
import "../../assets/styles/elements/elements.inputs.css";

//Props
interface IFInputPassword {
  placeholder: string;
  value: string;
  name: string;
  color: string;
  width: string;
  height: string;
  classes: string;
  responsive: boolean;
  autoFocus: boolean;
  disabled: boolean;
  readOnly: boolean;
  onChange(e: any): void;
  onInput(e: any): void;
  onBlur(e: any): void;
  onFocus(e: any): void;
  onClick: () => void;
};
//valor x default
const defaultProps = {
  placeholder: "Text input",
  value: "",
  name: "",
  color: "primary",
  width: "w-full md:w-72",
  height: "h-12",
  classes: "",
  responsive: true,
  autoFocus: false,
  disabled: false,
  readOnly: false,
  onChange: null,
  onClick: null,
  onInput: null,
  onBlur: null,
  onFocus: null,
};


export const InputPassword = (props: IFInputPassword) => {
  // Data
  const [viewPassword, setViewPassword] = useState(false);
  const inputElement = useRef(null);

  const color = () => {
    const options: any = {
      primary: "input-classic--primary",
      secondary: "input-classic--secondary",
    };

    return options[props.color];
  };

  useEffect(() => {
    focusReturn(inputElement);
  }, [viewPassword]);

  return (
    <>
      <div
        className={`
          relative
          ${props.width}
          ${props.height}
          ${props.classes}
        `}
      >
        <input
          required
          ref={inputElement}
          className={`input-classic input-classic--content ${color()}`}
          name={props.name}
          placeholder={props.placeholder}
          disabled={props.disabled}
          readOnly={props.readOnly}
          // minLength={8}
          // pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#|°¡¿?!@$%^&*-_]).{8,}$"
          value={props.value}
          type={viewPassword ? "text" : "password"}
          onClick={props.onClick}
          onChange={props.onChange}
          onBlur={props.onBlur}
          onInput={props.onInput}
          autoFocus={ props.autoFocus }
        />
        <FontAwesomeIcon
          icon={viewPassword ? faEye : faEyeSlash}
          className="input-classic__btn"
          onClick={() => setViewPassword(!viewPassword)}
        />
      </div>
    </>
  );
};
InputPassword.defaultProps = defaultProps;
