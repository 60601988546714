import React from 'react'

// Elements import
import { ButtonIcon, ModalClassic } from '../../elements/_elements.main'

//Store import
import { processState, processMutations } from "../../store/process/_process.main"

// Assets import
import iconExit from "../../assets/icons/exit.svg";

export const HomeProcessVideoModal = () => {

    const { showModalVideoProcess } = processState();
    const { setShowModalVideoProcess } = processMutations();

    const handleClose = ()=>{
        setShowModalVideoProcess(!showModalVideoProcess)
    }

  return (
    <ModalClassic size="small" classes="py-7 px-8">
        <ButtonIcon
            icon={ iconExit }
            onClick={ handleClose }
            color="error"
            width="w-7"
            height="h-7"
            classes="modal__btn-close text-white"
        />
    
        <iframe src="https://www.vidlii.com/embed?v=KaeemGKdmKA&a=0" frameBorder={0} width="100%" height="450px"></iframe>
    </ModalClassic>
  )
}
