// Utils import
import { getItemLocal } from "./utils.storage";
import { SAVE_AUTH_LOCAL_STORAGE } from "../app.tickets";

export const getConfig = async () => {
    const getScheme = (await getItemLocal(SAVE_AUTH_LOCAL_STORAGE))
    const tokenAuth = getScheme?.token ?? ''
    const authenticationScheme = getScheme?.authenticationScheme ?? '' 
    const data = {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `${authenticationScheme} ${tokenAuth}`
        },
    }
    return data
}