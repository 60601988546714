import React from 'react'

//Components import
import { LoginFormCreatorCode } from '../components/LoginFormCreatorCode'

//Assets import
import logoIdenti5IDFull from "../../../assets/img/logoIdenti5ID-full.svg";

export const LoginCreatorCode = () => {
  return (
    <div className='flex flex-col justify-center items-center h-full'>
      <img src={ logoIdenti5IDFull } alt="logo Identi5ID" className="mb-5 object-cover w-44" />
      <h3 className='text-purple-900 text-xl mb-7'>Inicia aquí el <span className='font-bold'>proceso de login</span></h3>
      <LoginFormCreatorCode />
    </div>
  )
}
