import React from 'react'

// Components import
import { LoaderPage } from "./LoaderPage";
import { LoaderPageText } from "./LoaderPageText";
import { LoaderCircle } from "./LoaderCircle";

interface IFLoaderPageType{
    type:'icon'|'text'|'circle';
}

const defaultProps = {
    type: 'circle'
}

export const LoaderPageType = (props:IFLoaderPageType) => {

  const loaderType = {
      icon: <LoaderPage/>,
      text: <LoaderPageText/>,
      circle: <LoaderCircle/>
  }

  return (
    <>{ loaderType[props.type] }</>
  )
}

LoaderPageType.defaultProps = defaultProps
