import create from "zustand";

//Store import
import { dashboardMutations } from "../../store/dashboard/_dashboard.main";

//External Store import
import { alertsMutations } from "../../../../store/alerts/_alerts.main";

//Utils import
import { restGet, restPost, restPut } from "../../../../utils/utils.rest";

//Tickets import
import {
  URL_GET_USAGE_COUNTER_CODE,
  URL_GET_WRONG_COUNTER_CODE,
  URL_CREATE_CODE,
  URL_VALIDATE_CODE,
  URL_GET_INVITATION_CODE_DETAILS
} from "../../../../app.tickets";

//Store Getters
const setDashboardCreatorMutation = dashboardMutations.getState();
const setAlertsMutation = alertsMutations.getState();

export const dashboardActions = create(() => ({
  getListUsageCounterCode: async () => {
    try {
      const res = await restGet({url: URL_GET_USAGE_COUNTER_CODE});
      const getData = res.data.data
      const setUsageCounterCode = getData.filter((item:any)=>{
        return item.code !== null
      })

      setDashboardCreatorMutation.setUsageCounterList(setUsageCounterCode)

    } catch (error) {
      
    }
  },
  updateCode: async (payload:{id:number;customerGroupName:string;code:string;active:boolean}) => {
    setDashboardCreatorMutation.setLoadingEditCode(true)
    try {
      const res = await restPut({url: URL_VALIDATE_CODE, data: payload});

      if(res.data.response){
        const setResponse = {
          title: "Edición correcta!",
          message: "El código se editó correctamente.",
          alertType: "toast",
          type: "success",
        };
        setAlertsMutation.setInfoAlert(setResponse);

        setDashboardCreatorMutation.setShowModalEdit(false)
        dashboardActions.getState().getListUsageCounterCode()
      }
    } catch (error) {
      const setResponse = {
        title: "Error en el proceso",
        message: "Por favor intenta nuevamente.",
        alertType: "toast",
        type: "error",
      };
      setAlertsMutation.setInfoAlert(setResponse);
    }finally{
      setDashboardCreatorMutation.setLoadingEditCode(false)
    }
  },
  getListWrongCounterCode: async (payload:{pageNumber:number;rowsOfPage:number}) => {
    const { pageNumber, rowsOfPage } = payload
    try {
      const res = await restGet({url: `${URL_GET_WRONG_COUNTER_CODE}/${pageNumber}/${rowsOfPage}`});
      const getData = res.data.data.useByCodes
      const getTotal = res.data.data.totalWrongUseByCode

      const setWrongCounterCode:[] = getData.filter((item:any)=>{
        return item.code !== null
      })
      setDashboardCreatorMutation.setTotalEvents(getTotal);
      setDashboardCreatorMutation.setWrongCounterList(setWrongCounterCode)

    } catch (error) {
      
    }
  },

  createNewCode: async (payload:{code:string;group:string}) => {
    setDashboardCreatorMutation.setLoadingNewCode(true)
    try {
      const { code, group } = payload
      const setData = {
        code: code,
        CustomerGroupName: group
      }
      const res = await restPost({url: URL_CREATE_CODE, data: setData})

      if(res.data.response){
        const setResponse = {
          title: "Código creado!",
          message: "El código se creo correctamente.",
          alertType: "toast",
          type: "success",
        };
        setAlertsMutation.setInfoAlert(setResponse);
        
        setDashboardCreatorMutation.setNewCode({code:'',group:''})
        setDashboardCreatorMutation.setStatusCard(false)
        dashboardActions.getState().getListUsageCounterCode()
      }

    } catch (err:any) {

      const { message } = err.response.data;

      const setResponse = {
        title: "Error en el proceso!",
        message: message,
        alertType: "modal",
        type: "error",
        subtitle:"",
        cancelButton:{
          text:'Cerrar',
          onClick:null
        },
        successButton: {
            text:'Intentar nuevamente',
            onClick:null
        },
        onClick:()=>{
          setAlertsMutation.setShowAlert(false)
        },
      };
      setAlertsMutation.setInfoAlert(setResponse);
      
    } finally {
      setDashboardCreatorMutation.setLoadingNewCode(false)
    }
  }

}));
