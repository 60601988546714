import create from "zustand";

//Types import
import { IFProcessState } from "../../types/types.process";

export const processState = create<IFProcessState>(() => ({
  process: '',
  processMicroblink: true,
  fullDocumentInfo:{
    frontFirstName: '',
    frontLastName: '',
    frontFullName: '',
    frontDocumentNumber: '',

    //Document Back
    backDay: '',
    backMonth: '',
    backYear: '',
    backDateOfBirth: '',
    backPlaceOfBirth: '',
    backIssuingAuthority: '',
    backSex: '',
    backDateOfIssue: '',

    //Document Barcode
    barcodeFirstName: '',
    barcodeLastName: '',
    barcodeFullName: '',
    barcodeDocumentNumber: '',
    barcodeDay: '',
    barcodeMonth: '',
    barcodeYear: '',
    barcodeDateOfBirth: '',
    barcodeSex: '',

    //Images
    faceImage: '',
    fullDocumentFrontImage: '',
    fullDocumentBackImage:'',

    //Reader document or not
    documentReader: false,
  },
  documentFaceImage: '',
  documentStatus: false,
  event: '',
  showProcess:"steps",
  selectProcess:{
    documentType:'co',
    processType:'yes'
  },
  showCounter: false,
  infoProcess:null,
  showModalSuccess: false,
  showPulse:false,
  isLoading: {
    recognition: false,
    validation: false
  },
  showModalVideoProcess: true,
  showStepsProcessModal: false,
  viewAnswer: false,
  successAnswer: null,
  answer: null,
}));
