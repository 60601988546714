
import { Routes, Route } from "react-router-dom";

import { LoginCreatorCode } from "../views/LoginCreatorCode";

export const RoutesPublic = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<LoginCreatorCode />} />
      </Routes>
    </>
  );
};
