//Router import
import { Routes, Route } from "react-router-dom";

// Routes
import { RoutesPrivate } from "./routes.private";
import { RoutesPublic } from "./routes.public";

//Store import
import { userState } from "../store/user/_user.main";
import { loginMutations } from "../store/login/_login.main";

//Utils import
import { dataDecrypt } from "../utils/utils.encrypt-decrypt";
import { validateOverTime } from "../utils/utils.session";

export const RoutesApp = () => {
  const { user } = userState();
  const { logout } = loginMutations();

  const auth = user && dataDecrypt(user).logged ? true : false;

  if (auth) {
    const session = validateOverTime(dataDecrypt(user).sessionTime);
    if (!session) logout();
  }

  return (
    <div className="cover--absolute overflow-hidden bg-gradient-to-br z-0">
      <Routes>
        <Route
          path="/*"
          element={!auth ? <RoutesPublic /> : <RoutesPrivate />}
        />
      </Routes>
    </div>
  );
};
