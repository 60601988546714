import React from 'react'

interface IFSectionTitle{
    title:string;
}
export const SectionTitle = (props:IFSectionTitle) => {
  return (
    <>
        <h3 className='font-medium text-xl my-5'>{ props.title }</h3>
    </>
  )
}
