//External libraries
const CryptoJS = require("crypto-js");

const ENCRYPT_KEY = process.env.REACT_APP_ENCRYPT_KEY
const ENCRYPT_IV = process.env.REACT_APP_ENCRYPT_IV

export const dataEncrypt = (data:any) =>{

    let jsonData = JSON.stringify(data)

    let key = CryptoJS.enc.Utf8.parse(ENCRYPT_KEY);
    let iv = CryptoJS.enc.Utf8.parse(ENCRYPT_IV);
    const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(jsonData), key,
        {
            keySize: 128 / 8,
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
    
    return encrypted.toString();
}

export const dataDecrypt = (data:any) =>{

    if(data){
        const key = CryptoJS.enc.Utf8.parse(ENCRYPT_KEY);
        const iv = CryptoJS.enc.Utf8.parse(ENCRYPT_IV);
        const bytes  = CryptoJS.AES.decrypt(data, key, {
            keySize: 128 / 8,
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });

        
        const getDecryptData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return getDecryptData;
    }else{
        return null
    }
}