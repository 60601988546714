import React, { useEffect, useRef } from "react";

interface IFFormClassic {
  children: any;
  classes: string;
  autoComplete:'on'|'off';
  onSubmit(e: any): void;
}

const defaultProps = {
  classes: "",
  autoComplete: 'off',
  onSubmit: null,
};

export const FormClassic = (props: IFFormClassic) => {

  const formElement = useRef(null);

  const handleSubmit = (form: any) => {
    form.addEventListener("submit", (e: any) => {
      e.preventDefault();
    });
  };

  useEffect(() => {
    formElement.current && handleSubmit(formElement.current);
  }, []);

  return (
    <>
      <form
        ref={formElement}
        onSubmit={props.onSubmit}
        className={props.classes}
        autoComplete={ props.autoComplete }
      >
        {props.children}
      </form>
    </>
  );
};

FormClassic.defaultProps = defaultProps;
