import create from "zustand";

//Interfaces import
import { IFShowProcess, IFDocumentTypes, IFProcessType } from "../../types/types.process";

//Utilis import
import { validateDevice } from "../../utils/utils.device";

//Store import
import { processState } from "./_process.main";
import { alertsMutations } from "../alerts/_alerts.main";
import { userMutations } from "../user/_user.main";

//Assets import
import iconFrontCard from "../../assets/img/iconFrontCard.png";

//Store Getters
const setAlertsMutation = alertsMutations.getState();
const setUserMutation = userMutations.getState();

export const processMutations = create(() => ({

  setProcess: (payload: string) => {
    processState.setState({
      process: payload,
    });
  },
  
  setProcessMicroblink: (payload: boolean) => {
    processState.setState({
      processMicroblink: payload,
    });
  },
  
  setDocumentStatus: (payload: boolean) => {
    processState.setState({
      documentStatus: payload,
    });
  },
  
  setDocumentFaceImage: (payload: string) => {
    processState.setState({
      documentFaceImage: payload,
    });
  },
  
  setFullDocumentInfo: (payload:any) => {
    processState.setState({
      fullDocumentInfo: payload,
    });
  },
  
  setEvent: (payload: string) => {
    processState.setState({
      event: payload,
    });
  },
  
  setShowConter: (payload: boolean) => {
    processState.setState({
      showCounter: payload,
    });
  },
  
  setShowProcess: (payload:IFShowProcess['showProcess']) => {
    processState.setState({
      showProcess: payload,
    });
  },

  setInfoProcess: (payload: any) => {
    processState.setState({
      infoProcess: payload,
    });
    processMutations.getState().setShowModalSuccess(true)
  },
  
  setShowModalSuccess: (payload: boolean) => {
    processState.setState({
      showModalSuccess: payload,
    });
  },
  
  setShowModalVideoProcess: (payload: boolean) => {
    processState.setState({
      showModalVideoProcess: payload,
    });
    localStorage.setItem("videoSeen", JSON.stringify(!payload))
  },

  setShowStepsProcessModal: (payload: boolean) => {
    processState.setState({
      showStepsProcessModal: payload,
    });
  },
  
  setSelectProcess: (payload:{
    documentType:IFDocumentTypes["documentType"],
    processType:IFProcessType["processType"]
  }) => {
    processState.setState({
      selectProcess: payload,
    });
  },
  
  setShowPulse: (payload: boolean) => {
    processState.setState({
      showPulse: payload,
    });
  },

  setIsLoading: (payload:{recognition:boolean, validation:boolean }) => {
    processState.setState({
      isLoading: payload,
    });
  },
  
  setViewAnswer: (payload: boolean) => {
    processState.setState({
      viewAnswer: payload,
    });
  },
  
  setSuccessAnswer: (payload: any) => {
    processState.setState({
      successAnswer: payload,
    });
  },

  handleSideChange(payload:{sideA?:'success'|'error';sideB?:'success'|'error';}){

    function getRotate(){
      return validateDevice() === 'Desktop' ? 'no' : 'yes'
    }

    const getAlertType:any = {
        sideA:{
            success: {
                title:'Bien hecho',
                message: `Ahora, gira el documento
                para terminar el registro`,
                alertType: "document",
                icon: iconFrontCard,
                cancelButton:{
                  text:'',
                  onClick:null
                },
                successButton: {
                  text:'Entendido!',
                  onClick:()=>{
                    setAlertsMutation.setShowAlert(false)
                  }
                },
                rotate: getRotate()
            },
            error: {
                title: '',
                message: `El registro de la parte
                anterior del documento
                no fue posible`,
                alertType: "document",
                icon: iconFrontCard,
                cancelButton:{
                  text:'Cerrar',
                  onClick:()=>{
                    setAlertsMutation.setShowAlert(false)
                    processMutations.getState().setShowProcess("steps")
                  } 
                },
                successButton: {
                  text:'Inténtalo nuevamente',
                  onClick:()=>{
                    setAlertsMutation.setShowAlert(false)
                    processMutations.getState().setShowProcess("steps")
                  }
                },
                rotate: getRotate()
            }
        },
        sideB:{
            success: {
                title:'Tu documento de identidad',
                message: `fue registrado exitosamente
                Ahora crea el registro facial`,
                subtitle: '',
                alertType: "modal",
                icon: '',
                cancelButton:{
                  text:'',
                  onClick: null
                },
                successButton: {
                  text:'Continuar',
                  onClick:()=>{
                    setAlertsMutation.setShowAlert(false)
                    processMutations.getState().setShowConter(true)
                  }
                },
                rotate: 'no'
            },
            error: {
                title: '',
                message: `El registro de la parte
                posterior del documento
                no fue posible`,
                alertType: "document",
                icon: iconFrontCard,
                cancelButton:{
                  text:'Cerrar',
                  onClick:()=>{
                    setAlertsMutation.setShowAlert(false)
                    processMutations.getState().setShowProcess("steps")
                  } 
                },
                successButton: {
                  text:'Inténtalo nuevamente',
                  onClick:()=>{
                    setAlertsMutation.setShowAlert(false)
                    processMutations.getState().setShowProcess("steps")
                  }
                },
                rotate: getRotate()
            }
        }
    }

    const getStructure = Object.entries(payload)[0]
    const side = getStructure[0]
    const type = getStructure[1]
    const { title, message, subtitle, icon, cancelButton, successButton, alertType, rotate } = getAlertType[side][type]
    
    const setResponse = {
        title,
        icon,
        message,
        subtitle,
        alertType,
        rotate,
        cancelButton,
        successButton,
        outsideClick: false,
        onClick: ()=> null,
        type,
    };

    setAlertsMutation.setInfoAlert(setResponse);
  },

  cognoxResponse: (payload: any) => {
    
    const data = payload

    const errorCode:any = {
      400: "error",
      500: "error",
      200: "success"
    }

    const setMessage:any = {
      successStart:{
        title: 'El reconocimiento facial',
        message: 'fue registrado exitosamente',
        subtitle: '',
        cancelButton: {
          text: '',
          onClick:null
        },
        successButton: {
          text: 'Cerrar',
          onClick:()=>{
            setAlertsMutation.setShowAlert(false)
            // processMutations.getState().setShowProcess("steps")
            window.location.reload()
          }
        },
        buttonText: '',
        onClick:()=>{
          setAlertsMutation.setShowAlert(false)
          // processMutations.getState().setShowProcess("steps")
          window.location.reload()
        },
        outsideClick: false,
      },
      successEnd:{
        title:`¡Felicitaciones!`,
        message:`Has realizado con éxito la demostración completa`,
        subtitle:'',
        buttonText: '',
        cancelButton: {
          text: '',
          onClick:null
        },
        successButton: {
          text: 'Solicítala aquí',
          onClick:null 
        },
        onClick:()=>{
          if(validateDevice() === 'Desktop'){
            window.open(`https://web.whatsapp.com/send?phone=${process.env.REACT_APP_CONTACT_LINK}`, "_blank")
          }else{
            window.open(`whatsapp://send?phone=${process.env.REACT_APP_CONTACT_LINK}`, "_blank")
          }
          setAlertsMutation.setShowAlert(false)
          // processMutations.getState().setShowProcess("steps")
          window.location.reload()
        },
        outsideClick: false,
      },
      error: {
        title: 'El reconocimiento facial',
        message: 'no fue registrado',
        subtitle: '',
        cancelButton: {
          text: 'Cerrar',
          onClick:()=>{
            setAlertsMutation.setShowAlert(false)
            // processMutations.getState().setShowProcess("steps")
            window.location.reload()
          } 
        },
        successButton: {
          text: 'Inténtalo nuevamente',
          onClick:()=>{
            setAlertsMutation.setShowAlert(false)
            processMutations.getState().setShowConter(true)
            // processMutations.getState().setShowProcess("process")
          } 
        },
        buttonText: '',
        onClick:()=>{
          setAlertsMutation.setShowAlert(false)
          // processMutations.getState().setShowProcess("steps")
          window.location.reload()
        },
        outsideClick: false,
      }
    }

    if (!data.response) {

      const setResponse = {
        title: "La lectura no fue correcta",
        message: 'por favor intenta nuevamente',
        subtitle: '',
        onClick:()=>{
          setAlertsMutation.setShowAlert(false)
          // processMutations.getState().setShowProcess("steps")
          window.location.reload()
        },
        alertType: "modal",
        cancelButton: {
          text: 'Cerrar',
          onClick:()=>{
            setAlertsMutation.setShowAlert(false)
            // processMutations.getState().setShowProcess("steps")
            window.location.reload()
          } 
        },
        successButton: {
          text: 'Inténtalo nuevamente',
          onClick:()=>{
            setAlertsMutation.setShowAlert(false)
            // processMutations.getState().setShowProcess("process")
            window.location.reload()
          } 
        },
        type: errorCode[data.code]
      }
      setAlertsMutation.setInfoAlert(setResponse)

    } else {

      const statusRecognition = data.hasOwnProperty("status") ? data.status.recognition : data.recognition
      const statusValidation = data.process === 'recognition'
        ? true
        : data.hasOwnProperty("status") ? data.status.validation : data.validation;

      const success = statusRecognition && statusValidation ? true : false;

      const code = success ? 200 : 400

      let setResponse = {}
      let statusPulse = false

      if(data.process !== 'validation'){

        const setCodeMessage = success ? 'successStart' : 'error'

        const { title, subtitle, message, onClick, outsideClick, cancelButton, successButton } = setMessage[setCodeMessage]

        setResponse = {
          title,
          subtitle,
          message,
          onClick,
          outsideClick,
          alertType: "modal",
          cancelButton,
          successButton,
          type: errorCode[code]
        }

        if (statusRecognition) {        
          const isEnabled = statusRecognition ? 'enabled' : 'disabled'
          setUserMutation.setStatus(isEnabled)
          statusPulse = true
        }

        setAlertsMutation.setInfoAlert(setResponse)
      }else{

        if(errorCode[code] === 'success'){
          const { title, subtitle, message, buttonText, onClick, outsideClick, cancelButton, successButton } = setMessage['successEnd']
          setResponse = {
            title,
            subtitle,
            message,
            buttonText,
            onClick,
            outsideClick,
            cancelButton,
            successButton,
            alertType: "modal",
            type: errorCode[code]
          }
          setAlertsMutation.setInfoAlert(setResponse)
          // processMutations.getState().setInfoProcess(setResponse)
        }else{
          const { title, subtitle, message, buttonText, onClick, outsideClick, cancelButton, successButton } = setMessage['error']
          setResponse = {
            title,
            subtitle,
            message,
            buttonText,
            cancelButton,
            successButton,
            onClick,
            outsideClick,
            alertType: "modal",
            type: errorCode[code]
          }
          setAlertsMutation.setInfoAlert(setResponse)
        } 
      }
      
      processMutations.getState().setShowPulse(statusPulse)

    }
  },
}));
