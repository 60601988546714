import React from "react";

interface IFSelectClassic {
  name:string;
  selected:string;
  classes:string;
  required:boolean;
  onChange(e: any): void;
  options:{
    value:string | number;
    text:string;
    selected?:string;
  }[];
}

const defaultProps = {
  name: '',
  selected: null,
  classes:'',
  onChange: null,
  options: null,
  required: false,
}

export const SelectClassic = (props: IFSelectClassic) => {

  return (
    <>
      <select
        name={ props.name }
        onChange={ props.onChange }
        className={`${props.classes} border-gray-50 border rounded-md text-base`}
        required={ props.required }
        defaultValue={ props.selected }
      >
        {
          props.options.map((option)=>(
            <option
              key={ option.value }
              className="bg-gray-50"
              value={ option.value }
          >
            { option.text }
          </option>
          ))
        }
      </select>
    </>
  );
};

SelectClassic.defaultProps = defaultProps
  