import React, { useEffect, useRef } from 'react'

//External libraries import

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'identi5id-integrator': IFIdenti5IDIntegrator;
        }
    }
}

enum CUSTOM_EVENT_NAMES{
    READY = "identi5IDIntegratorLoaded",
    RESPONSE = "identi5IDIntegratorResponse",
    MODULE_FACE_READY = "identi5IDLoaded",
    FACE_RESPONSE = "identi5IDResponse",
    DOCUMENT_RESPONSE = "identi5IDDIResponse",
    DOCUMENT_CHANGE_SIDE = "identi5IDDIChangeSide"
}

interface IFIdenti5IDIntegrator extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> {
    primary: string,
    secondary: string,
    avatar_background:string;
    token_auth: null | string;
    document_type:string;
    id:string;
    process:string;
    event:string;
}

interface IFProcess{
    primary:string;
    secondary:string;
    avatar_background:string;
    token_auth:null | string;
    document_type:string;
    id:string;
    process:string;
    event:string;
    loadedComponent:(e:any)=>void
    changeSide:(e:any)=>void
    responseClient:(e:any)=>void
}

const defaultProps = {
    primary:'#30D1FC',
    secondary:'',
    avatar_background:'',
    token_auth:'',
    document_type:'',
    id:null,
    process:'',
    event:'',
    loadedComponent:null,
    changeSide:null,
    responseClient:null
}

export const Identi5IDIntegrator = (props:IFProcess) => {

    const identi5IDIntegrator = useRef<HTMLElement>(null);
    
    useEffect(() => {
        // CognoxConnection()
        identi5IDIntegrator.current && document.addEventListener(CUSTOM_EVENT_NAMES.DOCUMENT_CHANGE_SIDE, props.changeSide);
        identi5IDIntegrator.current && document.addEventListener(CUSTOM_EVENT_NAMES.MODULE_FACE_READY, props.loadedComponent);
        identi5IDIntegrator.current && document.addEventListener(CUSTOM_EVENT_NAMES.RESPONSE, props.responseClient);

        return () => {
            document.removeEventListener(CUSTOM_EVENT_NAMES.DOCUMENT_CHANGE_SIDE, props.changeSide);
            document.removeEventListener(CUSTOM_EVENT_NAMES.MODULE_FACE_READY, props.loadedComponent);
            document.removeEventListener(CUSTOM_EVENT_NAMES.RESPONSE, props.responseClient);
        };
    },[]);
       
    return (
        <>
            <identi5id-integrator
                ref={ identi5IDIntegrator }
                primary={ props.primary }
                secondary={ props.secondary }
                id={ props.id }
                token_auth={props.token_auth}
                process={ props.process }
                event={ props.event }
                avatar_background={ props.avatar_background }
                document_type={ props.document_type }
            ></identi5id-integrator>
        </>
    )
}

Identi5IDIntegrator.defaultProps = defaultProps