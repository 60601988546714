//Elements import
import { CardStatus, ButtonClassic, Waves, ButtonLink, ButtonIcon } from "../../elements/_elements.main"

//Components import
import { StepsProcessModal } from "./StepsProcessModal";

//Store import
import { userState } from "../../store/user/_user.main"
import { processMutations, processState } from "../../store/process/_process.main"
import { loginActions, loginMutations } from "../../store/login/_login.main"

//Utils import
import { dataDecrypt } from "../../utils/utils.encrypt-decrypt"
import { validateDevice } from "../../utils/utils.device";

//Assets import
import recognition from "../../assets/img/recognition.jpg"
import validation from "../../assets/img/validation.jpg"
import characteristics from "../../assets/img/caracteristicas.png"
import logoIdenti5IDFull from "../../assets/img/logoIdenti5ID-full.svg";
import '../../assets/styles/views/process.css'

import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

export const Steps = () => {
  
  const { isLoading, showStepsProcessModal } = processState()
  const { setProcess, setShowProcess, setProcessMicroblink, setShowStepsProcessModal } = processMutations()
  const { logout } = loginMutations()
  const { generateTokenAuth } = loginActions()
  
  const { status, user } = userState();
  const name = dataDecrypt(user)?.nickName
  const isEnabled = dataDecrypt(status)

  const showPulse = isEnabled === "enabled" ? true : false

  const handleClick = (payload:any) => {
    if(payload === 'recognition'){
      // setShowProcess('details')
      setShowStepsProcessModal(true)
    }else{
      generateTokenAuth(payload)
      setProcess(payload)
      setProcessMicroblink(false)
    }
  }

  const handleContact = () => {
    if(validateDevice() === 'Desktop'){
      window.open(`https://web.whatsapp.com/send?phone=${process.env.REACT_APP_CONTACT_LINK}`, "_blank")
    }else{
      window.open(`whatsapp://send?phone=${process.env.REACT_APP_CONTACT_LINK}`, "_blank")
    }
  }

  const registerDesc = `
    Realiza la lectura del documento de identidad y el registro de tu
    rostro para continuar el proceso de validación.
  `
  
  const validationDesc = `
    Basados en los procesos generados en el registro,
    realiza la autenticación de tu rostro para validar tu identidad.
  `

  return (
    <>
      <div className="flex items-center 2xl:h-full w-full overflow-x-hidden sm:overflow-auto relative">
        <div className="p-2 w-full grid grid-cols-12 items-center relative z-20">
          <div className="flex flex-col lg:flex-row items-center justify-center col-span-12 lg:col-span-5 mx-auto mt-20 lg:mt-0 mb-3">
            <a href="https://www.identi5id.com/" target="_blank" rel="noopener noreferrer">
              <img
                src={logoIdenti5IDFull}
                alt="logo Identi5ID"
                className="mx-auto lg:ml-0 object-cover w-52"
              />
            </a>
            <div className="max-w-xs mt-12 lg:mt-0 lg:ml-7">
              <p className="font-thin leading-5">
                <span className="block text-main font-bold text-xl w-64 truncate">Hola, { name }</span>
                <span className="block font-bold">Bienvenid@ a un lugar seguro!</span>
                <span className="font-thin text-sm">Aquí puedes realizar la demostración</span>
                <span className="font-bold text-sm text-main"> de seguridad biométrica facial</span></p>
            </div>
          </div>
          <div className="col-start-12">
            <ButtonClassic
              height="h-9"
              width="w-28"
              color="secondary"
              text="Salir"
              onClick={() => logout()}
              classes="ml-auto font-light absolute top-5 right-5 lg:top-10 lg:right-10"
            />
          </div>
          <CardStatus
            image={ recognition }
            classes="col-span-12 mt-2 mx-auto sm:w-80 lg:col-span-4"
            width="w-full"
            title="Etapa #1"
            subtitle="Registro"
            description={ registerDesc }
            buttonText="Registro"
            status="enabled"
            isLoading={ isLoading.recognition }
            showPulse={ !showPulse }
            onClick={ ()=> handleClick('recognition') }
          />
          <CardStatus
            image={ validation }
            classes={`col-span-12 mt-2 mx-auto sm:w-80 lg:col-span-4 ${isEnabled !== "enabled" ? "opacity-40":"opacity-100"}`}
            width="w-full"
            title="Etapa #2"
            subtitle="Autenticación"
            description={ validationDesc }
            buttonText="Autenticación"
            status={ isEnabled }
            isLoading={ isLoading.validation }
            showPulse={ showPulse }
            onClick={ ()=> {
              // setLoadingPage({status: true, type: "text"})
              handleClick('validation')
            }}
          />
          <div className="col-span-12 mt-12 lg:mt-0 lg:col-span-4 relative z-30">
            <h2 className="font-bold text-2xl mb-8 ml-3 text-primary leading-7">Características
              <span className="block">de nuestra solución</span>
            </h2>
            <div className="col-span-12 lg:col-span-4 w-11/12 font-poppins">
              <img
                src={ characteristics }
                alt="Características Identi5ID"
                className="mx-auto object-cover w-96"
              />
            </div>
          </div>
          <div className="col-span-12 mt-3 sm:hidden">
            <div className="relative z-20">
              <p className="font-bold text-center text-lg leading-5">Pregunta y descubre
                <span className="font-thin block">todo lo que puede hacer</span>
                <span className="block">nuestra potente solución</span>
                <span className="block">para la protección de datos</span>
                <span className="font-thin block">contra el acceso no autorizado</span>
              </p>
              <div className="flex justify-center items-center my-8">
                  <ButtonLink
                    classes="font-bold text-xs text-center text-main font-bold"
                    text="Clic para contactar un asesor"
                    onClick={ handleContact }
                  />
                  <ButtonIcon width="w-5" height="h-5" classes="p-1 bg-main ml-1 text-white" icon={ faChevronRight } onClick={ handleContact } />
              </div>
              <div className="my-8">
                <a href="https://www.identi5id.com/" target="_blank" rel="noopener noreferrer">
                  <img
                    src={logoIdenti5IDFull}
                    alt="logo Identi5ID"
                    className="mx-auto object-cover w-52"
                  />
                </a>
              </div>
            </div>
            <Waves classes="bottom-0 absolute z-10 w-[500px] h-[30vh]" color="255, 255, 255" />
          </div>
        </div>
        <Waves classes="bottom-[calc(100vh-225px)] sm:top-[initial] fixed sm:bottom-0 sm:h-[50vh] z-10" color="238, 242, 255" />
        <div className="bg-[#eef2ff] fixed bottom-0 h-[calc(100vh-225px)] w-full sm:hidden"></div>
      </div>
      { showStepsProcessModal && <StepsProcessModal/> }
    </>
  )
}
