import React from 'react';
import { Routes, Route } from 'react-router-dom'

//Views import
import { Content } from '../views/Content'

export const RoutesPrivate = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Content/>} />
      </Routes>  
    </>
  )
}
