import React, { useState, useEffect } from 'react'

//Components import
import { CardAddAndEditCreatorCode } from "./CardAddAndEditCreatorCode";

//Elements import
import { SectionTitle } from './SectionTitle';

//Store import
import { dashboardState, dashboardActions, dashboardMutations } from '../store/dashboard/_dashboard.main';

export const CardAddCreatorCode = () => {

    //Store
    const { usageCounterList, newCode, loadingNewCode } = dashboardState()
    const { setNewCode } = dashboardMutations()
    const { createNewCode } = dashboardActions()

    const [statusCard, setStatusCard] = useState(false)

    const handleClick = () => {
        const {code, group} = newCode
        createNewCode({code, group})
    }
    const handleChange = (payload:any) => {
        const { name, value } = payload.target
        setNewCode({...newCode, [name]:value})
    }

    const validateLenght = (payload:string):boolean => {
        const minCharacters = 4
        return payload.length >= minCharacters
    }

    useEffect(()=>{
        const getStatus = validateLenght(newCode.code) && validateLenght(newCode.group)
        setStatusCard(getStatus)
    },[newCode.code,newCode.group])

  return (
        <div>
            <SectionTitle
                title='Agregar nuevo código'
            />
            <CardAddAndEditCreatorCode
                index={ usageCounterList?.length! + 1 }
                code={ newCode.code }
                group={ newCode.group }
                onClick={ handleClick }
                onChange={ handleChange }
                status={ statusCard }
                statusCard={ statusCard }
                isLoading={ loadingNewCode }
            />
        </div>
  )
}
