import React from 'react';
// Styles import
import "../../assets/styles/elements/elements.inputs.css";

//Props
interface IFInputChoice {
  type: 'checkbox' | 'radio';
  name: string;
  value: string;
  id: string;
  color: string;
  width: string;
  height: string;
  classes: string;
  pattern: string;
  required: boolean;
  autoFocus: boolean;
  disabled: boolean;
  readOnly: boolean;
  checked: boolean;
  onChange(e: any): void;
  onInput(e: any): void;
  onBlur(e: any): void;
  onClick: () => void;
}

//valor x default
const defaultProps = {
  type: "checkbox",
  name: "",
  value: "",
  id: "",
  color: "primary",
  width: "w-12",
  height: "h-12",
  classes: "ring-primary ring-opacity-0",
  pattern: null,
  required: true,
  autoFocus: false,
  disabled: false,
  readOnly: false,
  checked: false,
  onChange: null,
  onInput: null,
  onBlur: null,
  onClick: null,
};

//component
export const InputChoice = (props: IFInputChoice) => {

  return (
    <>
      <input
        required={props.required}
        autoFocus={props.autoFocus}
        pattern={props.pattern}
        type={props.type}
        name={props.name}
        value={props.value}
        id={props.id}
        className={`input-choice ${props.classes} ${props.height} ${props.width}`}
        disabled={props.disabled}
        readOnly={props.readOnly}
        checked={props.checked}
        onClick={props.onClick}
        onChange={props.onChange}
        onBlur={props.onBlur}
        onInput={props.onInput}
      />
    </>
  );
};

InputChoice.defaultProps = defaultProps;
