interface IFTableClassic {
  clientIdPosition: number;
  colorRowEven: string;
  colorRowOdd: string;
  header: any;
  data: [];
  viewImage(e: any): void;
  getColor(e: any): string;
  onChange(e: any): void;
  onInput(e: any): void;
  onBlur(e: any): void;
  onClick: () => void;
  loading:any;
}
//valor x default
const defaultProps = {
  clientIdPosition: 0,
  colorRowEven: "bg-gray-800",
  colorRowOdd: "bg-gray-900",
  header: null,
  data: null,
  viewImage: null,
  getColor: null,
  onChange: null,
  onClick: null,
  onInput: null,
  onBlur: null,
  loading: null,
};

export const TableClassic = (props: IFTableClassic) => {

  return (
    <>
      <div className="overflow-auto pb-20 max-h-[412px]">
        <table className="realtive w-full">
          <thead className="bg-slate-400 text-white sticky top-0 z-20">
            <tr>
              {props.header.map((title: any, indTitle: number) => (
                <th
                  key={`th-${indTitle}`}
                  scope="col"
                  className="min-w-[120px] text-sm font-medium px-6 py-4"
                >
                  {title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="relative z-10">
            {props.data.map((row: any, index) => (
                <tr
                  key={row[0]}
                  className={`h-10 ${
                    props.data.length === index + 1 ? "pb-5" : ""
                  } ${
                    index % 2 ? props.colorRowEven : props.colorRowOdd
                  }  rounded-l-lg px-11 text-gray-500 `}
                >
                  {row.map((col: any, indexCol: number) => (
                        <td
                          key={`tr-${indexCol}`}
                          className="min-w-[120px] text-center text-sm whitespace-pre-line font-medium w-1/3"
                        >
                          {col}
                        </td>
                      ))
                  }
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};
TableClassic.defaultProps = defaultProps;
