import React from "react";

//Components import
import { StepsTitle } from "../../components/steps/StepsTitle";
//Elements import
import {
  ButtonClassic,
} from "../../elements/_elements.main";

//Store import
import { userState } from "../../store/user/_user.main"
import { processMutations } from "../../store/process/_process.main"
import { loginMutations, loginActions } from "../../store/login/_login.main";

//Utils import
import { dataDecrypt } from "../../utils/utils.encrypt-decrypt"

//Assets import
import background from '../../assets/img/backgroundDemo.png'
import logoIdenti5IDFull from "../../assets/img/logoIdenti5ID-full.svg";
import recognition from "../../assets/img/recognition.jpg";

export const StepsDetails = () => {
  const { logout } = loginMutations()

  const { setProcess, setShowProcess, setProcessMicroblink } = processMutations()
  const { generateTokenAuth } = loginActions()

  const { user } = userState();  
  const name = dataDecrypt(user)?.nickName

  const handleClick = (payload:any) => {
    setProcessMicroblink(true)
    generateTokenAuth(payload)
    setProcess(payload)
    setShowProcess('process')
  }

  return (
    <div className="relative h-full overflow-hidden">
      <div className="absolute block ml-auto top-5 right-5 z-30">
       {/*  <ButtonClassic
          height="h-9"
          width="w-28"
          color="secondary"
          text="Salir"
          onClick={() => logout()}
          classes="ml-auto font-light"
        /> */}
        <ButtonClassic
          height="h-9"
          width="w-28"
          color="secondary"
          text="Regresar"
          onClick={() => setShowProcess('steps')}
          classes="ml-auto font-light"
        />
      </div>
      <div className="absolute grid grid-cols-2 h-full w-full bottom-0 z-10">
        <img
          src={ recognition }
          alt={`Bienvenido ${name}`}
          className="object-cover sm:min-w-[500px] h-[40vh] sm:h-full w-full col-span-2 sm:col-span-1"
        />
        <div className="shadow-4xl shadow-white bg-white col-span-2 sm:col-span-1 z-10">
          {/* <img
            src={ background }
            alt="" 
            className='h-full w-full object-cover min-w-[700px]'/> */}
        </div>
      </div>
      <div className="cover--relative flex justify-center sm:justify-end items-end sm:items-center overflow-y-auto sm:p-10">
        <div className="bg-white shadow-3xl shadow-white sm:shadow-transparent sm:p-16 px-8 md:p-0 sm:max-w-xs lg:max-w-2xl z-10 sm:bg-transparent mb-12">
          <div className="mb-3 sm:mb-7">
            <StepsTitle
              title="Etapa #1"
              subtitle="Registro"
              classes="w-full sm:w-9/12 relative top-0"
            />
            <div className="text-primary text-base leading-5 md:text-xl 2xl:text-2xl text-justify sm:leading-7">
              <p className="my-3 sm:my-7">
                Para Appinit es fundamental asegurar la información
                de los usuarios que usen la plataforma de los clientes,
                por eso con nuestra solución Identi5ID se realiza el
                registro inicial en dos pasos, de una forma fácil,
                cómoda y segura.
              </p>
              <p>
                <span className="font-bold block sm:inline-block">Paso 1:</span> Escaneo y registro del documento de identidad
              </p>
              <p>
                <span className="font-bold block sm:inline-block">Paso 2:</span> Registro facial vivo
              </p>
              <p className="my-3 sm:my-7">
                Con este método la solución asocia la imagen del
                documento de identidad con la imagen del usuario,
                para que al usar posteriormente la platamorma se
                autentique y verifique de forma automática a la
                persona registrada en el registro inicial. 
              </p>
            </div>
          </div>
          <div>
            <ButtonClassic
              width="w-40"
              type="submit"
              color="secondary"
              text="Iniciar registro"
              classes="block mt-3"
              onClick={ ()=> handleClick("recognition") }
            />
          </div>
        </div>
      </div>
    </div>
  );
};
