import create from "zustand";

//Store import
import { userState } from "./_user.main";

//Utitls imports
import { dataEncrypt } from "../../utils/utils.encrypt-decrypt";

export const userMutations = create(() => ({
  setUser: (payload: any) => {
    const user = dataEncrypt(payload);
    userState.setState({
      user: user,
    });
  },
  setStatus: (payload: any) => {
    const status = dataEncrypt(payload);
    userState.setState({
      status: status,
    });
  },
  logoutUser: (payload: any) => {
    userState.setState({
      user: payload,
    });
    userMutations.getState().setStatus("disabled");
  },
}));
