import React, { useState, useEffect } from 'react'

//Components import
import { CardCreatorCode } from "./CardCreatorCode";

//Store import
import { dashboardState, dashboardMutations, dashboardActions } from "../store/dashboard/_dashboard.main";

//Elements import
import { SectionTitle } from "./SectionTitle";
import { Slider } from '../components/Slider';
import { InputClassic, ButtonClassic, ButtonIcon } from "../../../elements/_elements.main";

//Assets
import { faClose, faTrashAlt } from '@fortawesome/free-solid-svg-icons';


export const CardSlideCreatorCode = () => {

    //Store
    const { usageCounterList, showSlideInAdd } = dashboardState()
    const { setCardInfo, setShowModalEdit, setSlideInAdd } = dashboardMutations()
    const { getListUsageCounterCode } = dashboardActions()

    const [searchText, setSearchText] = useState('')

    const getCardCodes = usageCounterList!.slice(1, usageCounterList.length).filter((item)=>{
        const getCode = item.code.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        const getSearchedValue = searchText.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        return getCode.includes(getSearchedValue)
    })

    const handleClickCard = (payload:any) => {
        setCardInfo(payload)
        setShowModalEdit(true)
      }
      
    const handleAddCode = () => {
      setSlideInAdd(!showSlideInAdd)
    }
    
    const handleClean = () => {
      setSearchText('')
    }

    const setCards = getCardCodes?.map((card, index)=>(
        <CardCreatorCode
          index={ index + 1 }
          id={ card?.codeId }
          code={ card?.code }
          group={ card?.groupName }
          registerCounter={ card?.registerCounter }
          useCounter={ card?.useCounter }
          status={ card?.activeCode }
          onClick={ handleClickCard }
        />
    ))

    const buttonType = showSlideInAdd ? 'show' : 'close'
    const buttonAdd:any = {
      show: {
        message: "Cerrar",
        color: "bg-red-600"
      },
      close: {
        message: "Agregar",
        color: "bg-yellow-600"
      }
    }

    useEffect(()=>{
        getListUsageCounterCode()
    },[])

  return (
    <>
        <div className='relative'>
            <div className='flex items-center'>
                <SectionTitle
                    title='Códigos Usados'
                />
                <div className='ml-3 inline-flex ring-purple-700 ring-4 justify-center items-center rounded-full w-10 h-10 bg-purple-900 text-white'>
                    { getCardCodes?.length }
                </div>
            </div>
              <ButtonClassic
                height="h-11"
                width="w-24"
                text={ buttonAdd[buttonType].message }
                onClick={ handleAddCode }
                classes={`${buttonAdd[buttonType].color} ml-auto block sm:hidden font-light absolute right-0 top-0`}
              />
            <div className='relative flex items-center max-w-xs'>
              <InputClassic
                type='text'
                width='w-full'
                placeholder='Buscar código'
                value={ searchText }
                onChange={(e)=> setSearchText(e.target.value)}
              />
              <ButtonIcon
                  icon={ faTrashAlt }
                  onClick={ handleClean }
                  color="error"
                  width="w-7"
                  height="h-7"
                  classes="absolute right-3 text-white p-1"
              />
            </div>
        </div>
        <div className='mt-3'>
          {
            setCards.length <= 0
            ? <p className='p-8'>No hay información para mostrar...</p>
            : <Slider slides={ setCards } navigation={ false } pagination={ true } autoplay={ false }/>
          }            
        </div>
    </>
  )
}
