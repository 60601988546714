
import { Routes, Route } from "react-router-dom";

//Views import
import { Invite } from "../views/Invite";
import { LoginTermsAndConditions } from '../components/login/LoginTermsAndConditions';
import { MaintenancePage } from "../views/MaintenancePage";

export const RoutesPublic = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Invite />} />
        <Route path="/terminos-y-condiciones" element={<LoginTermsAndConditions/>} />
      </Routes>
    </>
  );
};
