import React, { useEffect, useCallback } from "react";

//Components import
import { Identi5IDIntegrator } from './Identi5IDIntegrator';
import { Identi5IDFace } from './Identi5IDFace';
import { ProcessCountDown } from "./ProcessCountDown";
import { StepsTitle } from "../steps/StepsTitle";

import {
  ButtonClassic,
} from "../../elements/_elements.main";

//Store import
import { userState } from "../../store/user/_user.main";
import { loginState } from "../../store/login/_login.main";
import {
  processState,
  processMutations,
} from "../../store/process/_process.main";

//Utils import
import { dataDecrypt } from "../../utils/utils.encrypt-decrypt";
import { validateDevice } from "../../utils/utils.device";

//Assets import
import logoAppinITFull from "../../assets/img/logoAppinit-full.svg";
import recognition from "../../assets/img/recognition.jpg";
import validation from "../../assets/img/validation.jpg"

export const Process = () => {
  const { user } = userState();
  const { tokenAuth } = loginState();
  const {
    process: processStore,
    showCounter,
    processMicroblink,
    selectProcess
  } = processState();

  const { handleSideChange, cognoxResponse, setShowProcess, setEvent, setShowConter, setProcessMicroblink, setDocumentStatus } =
    processMutations();

  const clientID = dataDecrypt(user).clientID;
  const event = "appInIt";
  const documentType = "co-hologram";
  const avatarBackground = "";

  const primaryColor = "#7902f8"; 
  const secondaryColor = "#ca8a04";

  const showInDevice = processMicroblink && (validateDevice() === 'Desktop')

  const getProcessType = (payload:string):{title:string; subtitle:string;image:string;} => {

    const getSubtitle = processMicroblink ? 'Paso #1 • Registro de documento' : 'Paso #2 • Registro facial'
    const processType:any = {
      recognition: {
        title: 'Etapa #1',
        subtitle: getSubtitle,
        image: recognition
      },
      validation: {
        title: 'Etapa #2',
        subtitle: 'Identificación',
        image: validation
      },
    };

    return processType[payload]
  }

  const handleLoadedComponent = (payload:any) => {
    if(payload.detail.loaded){
      setTimeout(()=>{
        if(processStore === 'recognition' && selectProcess.processType === 'yes'){
          handleSideChange({sideB:"success"})
        }else{
          setShowConter(true)
        }
      },100)
    }
  }

  const handleChangeSide = (payload: any) => {
    if(payload.detail === "identi5IDDIChangeSideA"){
      handleSideChange({sideA:"success"})
    }else{
      // handleSideChange({sideB:"success"})
      setProcessMicroblink(false)
    }
  }

  const handleResponse = (payload: any) => {
    const addProcessInAnswer = { ...payload.detail, process: processStore };
    cognoxResponse(addProcessInAnswer);
  };

  useEffect(() => {
    setEvent(event);
    return () => {
      setShowConter(false)
      setDocumentStatus(false)
    };
  },[]);

  return (
    <div className="relative h-full overflow-hidden">
      <div className='absolute top-5 left-5 sm:top-auto sm:bottom-10 sm:left-10 grid justify-center items-center z-40'>
        <a href="https://www.appinit.co/" target='_blank' rel="noopener noreferrer">
          <p className='text-sm text-white'>Powered by</p>
          <img src={ logoAppinITFull } alt="logo appinIT" className="mx-auto mb-4 object-cover w-20" />
        </a>
      </div>
      <div className="absolute block ml-auto top-5 right-5 z-50">
        <ButtonClassic
          height="h-9"
          width="w-28"
          color="secondary"
          text="Regresar"
          onClick={ ()=> {
            window.location.reload()
            // setShowProcess('steps')
          }}
          classes="ml-auto font-light"
        />
      </div>
      <div className="absolute h-full w-full bg-black opacity-90 z-20"></div>
      <div className="absolute grid grid-cols-2 h-full w-full bottom-0 z-10">
        <img
          src={ getProcessType(processStore)?.image }
          alt={`Bienvenido`}
          className="object-cover sm:min-w-[500px] h-[40vh] sm:h-full w-full col-span-2 sm:col-span-1"
        />
        <div className="shadow-4xl shadow-white bg-white col-span-2 sm:col-span-1 z-10">
          {/* <img
            src={ background }
            alt="" 
            className='h-full w-full object-cover min-w-[700px]'/> */}
        </div>
      </div>
      <div className="cover--relative flex justify-center items-center sm:items-center overflow-y-auto sm:p-10">
        <div className="flex flex-col flex--center items-center justify-between w-11/12 sm:w-full z-30">
          <div className="w-[100%] relative m-auto top-0 left-0 bottom-0 right-0 z-40">
            {showCounter && <ProcessCountDown />}
            {
              selectProcess.processType === 'yes'
              ?
              <div className="mt-0 sm:-mt-[calc(100vh-88vh)]">
                <Identi5IDIntegrator
                  id={clientID}
                  primary={primaryColor}
                  secondary={secondaryColor}
                  avatar_background={avatarBackground}
                  process={processStore}
                  event={event}
                  token_auth={ tokenAuth }
                  document_type={ documentType }
                  loadedComponent={ handleLoadedComponent }
                  changeSide={ handleChangeSide }
                  responseClient={ handleResponse }
                />
              </div>
              :
              <div className="mt-0 sm:-mt-[calc(100vh-88vh)]">
                <Identi5IDFace
                  client={ clientID }
                  primary={ primaryColor }
                  secondary={ secondaryColor }
                  avatar_background={ avatarBackground }
                  process={ processStore }
                  event={ event }
                  token_auth={ tokenAuth }
                  loadedComponent={ handleLoadedComponent }
                  responseClient={ handleResponse }
                />
              </div>
            }
          </div>
          <div className={`${showInDevice ? 'block' : 'hidden'}`}>
            <StepsTitle
              title={ getProcessType(processStore).title }
              subtitle={ getProcessType(processStore).subtitle }
              classes="w-full sm:w-auto relative sm:absolute sm:bottom-5 md:bottom-10 sm:right-5 md:right-10 z-50 text-sm"
              />
          </div>
        </div>
      </div>
    </div>
  )
};
