import React from 'react'

//Components import
import { CardAddCreatorCode } from "../components/CardAddCreatorCode";
import { dashboardState, dashboardMutations } from "../store/dashboard/_dashboard.main";

//Elements import
import { ButtonIcon } from '../../../elements/_elements.main';

// Assets import
import { faClose } from "@fortawesome/free-solid-svg-icons";


export const DashboardSlideAdd = () => {

  //Store
  const { showSlideInAdd } = dashboardState()
  const { setSlideInAdd } = dashboardMutations()
  
  const handleClose = () => {
    setSlideInAdd(!showSlideInAdd)
  }

  return (
    <div className={`${showSlideInAdd && 'dashboard-slidein-add__show'} dashboard-slidein-add`}>
      <ButtonIcon
          icon={ faClose }
          onClick={ handleClose }
          color="error"
          width="w-7"
          height="h-7"
          classes="modal__btn-close text-white"
      />
        <CardAddCreatorCode/>
    </div>
  )
}
