import create from "zustand";

//Store import
import { loginMutations, loginState } from "./_login.main";

//External Store import
import { alertsMutations } from "../../../../store/alerts/_alerts.main";

//Utils import
import { getTokenAuth, restPostNotEncrypted } from "../../../../utils/utils.rest";
import { setItemLocal, getItemLocal } from "../../../../utils/utils.storage"

//Tickets import
import {
  URL_LOGIN_CREATOR_CODE,
  SAVE_AUTH_LOCAL_STORAGE,
  URL_REFRESH_TOKEN
} from "../../../../app.tickets";

//Store Getters
const setLoginCreatorMutation = loginMutations.getState();
const setAlertsMutation = alertsMutations.getState();

export const loginActions = create(() => ({
  login: async () => {
    setLoginCreatorMutation.setLoadingLoginCreatorCode(true);
    
    try {
      const res = await restPostNotEncrypted({
        url: URL_LOGIN_CREATOR_CODE,
        data: loginState.getState().userInformation
      });
      
      const tokenAuth = await res.data.data;
      const { token, refreshToken, authenticationScheme } = tokenAuth
      
      setItemLocal(SAVE_AUTH_LOCAL_STORAGE, {
        username: loginState.getState().userInformation.username,
        isLogged: true,
        token: token,
        refreshToken: refreshToken,
        authenticationScheme: authenticationScheme
      })
      setLoginCreatorMutation.setIsLogged(true);

      const setResponse = {
        title: "Bienvenid@!",
        message: `Saludos ${loginState.getState().userInformation.username}`,
        alertType: "toast",
        type: "success",
      };
      setAlertsMutation.setInfoAlert(setResponse);

    } catch (err:any) {

      const { message } = err.response.data;

      const setResponse = {
        title: "Error en el proceso!",
        message: message,
        alertType: "toast",
        type: "error",
      };
      setAlertsMutation.setInfoAlert(setResponse);
    } finally {
      setLoginCreatorMutation.setLoadingLoginCreatorCode(false);
    }
  },

  refreshAccessToken: async () => {

    try {

        const getTokenInfo = await getItemLocal(SAVE_AUTH_LOCAL_STORAGE)
        const {username, refreshToken, token} = getTokenInfo ?? {}

        const setData = {
          username,
          refreshToken,
          token
        }

        const resp = await getTokenAuth({url:URL_REFRESH_TOKEN, auth: setData});

        if(resp.status === 200){
          const { token, refreshToken, authenticationScheme } = resp.data.data
          
          setItemLocal(SAVE_AUTH_LOCAL_STORAGE, {
            ...getTokenInfo,
            token,
            refreshToken,
            authenticationScheme
          })

          return token
        }else{
          setLoginCreatorMutation.logout()
        }
    } catch (error:any) {

      setLoginCreatorMutation.logout()

      const data = {
        code: 400,
        title: 'El tiempo de espera',
        message: 'ha excedido el límite',
        alertType: "modal",
        subtitle:"",
        cancelButton:{
          text:'Cerrar',
          onClick:null
        },
        successButton: {
            text:'Intentar nuevamente',
            onClick:null
        },
        onClick:()=>{
          setAlertsMutation.setShowAlert(false)
        },
      };
      setAlertsMutation.setInfoAlert(data);

    }
  }

}));
