import React from 'react'

import "../../assets/styles/elements/elements.loaders.css";

//Assets import
import logoLoading from "../../assets/img/loader.gif";

export const LoaderPage = () => {
  return (
    <div className="loader-page">
        <div className="loader-page__content">
            <img src={ logoLoading } alt="" />
            {/* <p className="loader-page__text">Cargando...</p> */}
        </div>
        <div className="loader-page__background"></div>
    </div>
  )
}
