import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

// Images import
import iconLoading from "../../assets/icons/loading.svg";
// Styles import
import "../../assets/styles/elements/elements.buttons.css";

// Props
interface IFButtonIcon {
  icon: string|IconProp;
  color: string;
  width: string;
  height: string;
  classes: string;
  disabled: boolean;
  loading: boolean;
  onClick: () => void;
};
const defaultProps = {
  icon: null,
  color: "primary",
  width: "w-11",
  height: "h-11",
  classes: "",
  disabled: false,
  loading: false,
  click: null,
};

export const ButtonIcon = (props: IFButtonIcon) => {

  const color = () => {
    const options: any = {
      primary: "btn-icon--primary",
      secondary: "btn-icon--secondary",
      error: "btn-icon--error",
    };
    return options[props.color];
  };

  const getIconType = (payload:any) => {
    if(typeof payload !== 'string'){
      return <FontAwesomeIcon
              className={`btn-icon__icon`}
              icon={ payload }
            />
    }
    return <img src={payload} alt="loading" className="btn-icon__icon" />
  }

  return (
    <>
      <button
        type="button"
        disabled={props.disabled || props.loading}
        className={`
          btn-icon
          ${color()}
          ${props.width}
          ${props.height}
          ${props.classes}
        `}
        onClick={props.onClick}
      >
        {!props.loading ? (
          getIconType(props.icon)
        ) : (
          <img src={iconLoading} alt="loading" className="btn-icon__loader" />
        )}
      </button>
    </>
  );
};
ButtonIcon.defaultProps = defaultProps;

