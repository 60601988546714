import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

//Elements import
import { ButtonIcon, Waves } from "../../elements/_elements.main";

//Store import
import { loginState } from "../../store/login/_login.main";

//Assets import
import arrowBack from "../../assets/icons/arrow_back.svg";

// Ga import
import ReactGA from "react-ga4";

export const LoginTermsAndConditions = () => {
  const { infoTerms } = loginState();

  const description: any = infoTerms?.description;

  const navigate = useNavigate();
  const backButton = () => {
    navigate(`/`);

    ReactGA.event({
      category: "login",
      action: "Click_exit_terms",
    });
  };

  useEffect(() => {
    ReactGA.event({
      category: "login",
      action: "Click_open_terms",
    });
  }, []);

  return (
    <>
        <div className="cover--relative flex--center bg-white overflow-hidden">
            <div className="h-full sm:rounded-xl overflow-hidden sm:h-[90%] w-full sm:w-4/5 md:w-5/6 lg:w-4/6 xl:w-3/6 flex shadow-lg shadow-slate-500 z-20">
                <div className="flex-1 bg-gray-100 ">
                    <div className="relative h-full sm:flex--center flex-col px-5 pt-20 pb-20 sm:pt-5 sm:pb-5 sm:px-10 text-primary">
                        <div className="absolute left-12 top-12 z-50">
                            <ButtonIcon width="w-5" height="h-5" classes="p-1 flex absolute -top-5 -left-5 bg-secondary" icon={ arrowBack } onClick={ backButton } />
                        </div>
                        <h3 className="my-5 py-2 px-0 text-2xl opaci font-bold">
                            Términos y Condiciones
                        </h3>
                        <div className="h-[70%] md:h-96 text-justify overflow-y-auto">
                            <div dangerouslySetInnerHTML={{__html: description}}/>
                        </div>
                        <div>
                        </div>
                    </div>
                </div>
            </div>
            <Waves classes="top-[calc(100vh-90vh)] sm:top-[initial] sm:bottom-0 sm:h-[50vh] z-10" color="238, 242, 255" />
        </div>
    </>
  );
};
