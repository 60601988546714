import React, { useState } from "react";
import { Link } from "react-router-dom";

//Elements import
import {
  ButtonClassic,
  FormClassic,
  InputChoice,
  InputForm,
  InputCountryPhone,
} from "../../elements/_elements.main";

//Store import
import {
  loginMutations,
  loginState,
  loginActions,
} from "../../store/login/_login.main";

//Assets import
import userRegister from "../../assets/img/register.jpg";
import logoAppinITFull from "../../assets/img/logoAppinit-full.svg";
import logoIdenti5IDFull from "../../assets/img/logoIdenti5ID-full.svg";
import logoAppinIT from "../../assets/img/logoAppinit.svg";

// Ga import
import ReactGA from "react-ga4";

export const LoginRegister = () => {
  const { setTemporaryData } = loginMutations();
  const { createRegister } = loginActions();

  const language = window.navigator.language.substring(0, 2) ?? "en";

  //Store
  const { loadingSignIn, inviteCode, temporaryData } = loginState();

  const [acceptedTerms, setAcceptedTerms] = useState(false);

  const onSubmit = () => {
    ReactGA.event({
      category: "login",
      action: "Submit_form_register",
    });

    const getData = { ...temporaryData };
    const newNickName = `${getData["nickName"]}`;
    const sendData = { ...temporaryData, nickName: newNickName };
    createRegister({
      ...sendData,
      language: language,
      invitationCodeId: inviteCode,
    });
    // submitLogin({nickName, email, cellphone, language, invitationCodeId: inviteCode})
  };

  const handleChoice = (payload: any) => {
    setAcceptedTerms(payload.target.checked);
  };

  const handleChange = (payload: any) => {
    const { name, value } = payload;

    // setUserData({ ...userData, [name]: value });
    setTemporaryData({ ...temporaryData, [name]: value });
  };

  const footerMessage = `
  <span class="font-bold">Con nuestra solución de seguridad facial</span>, puedes identificar a tus usuarios de una manera muy fácil
  <span class="font-bold">utilizando modelos de I.A. propios con un 98% de efectividad,</span> libre de sesgos de raza, género y controlando los riesgos de intentos
  de suplantación con un <span class="font-bold">95% de efectividad</span>, utilizando técnicas de detección de prueba de vida pasiva..
  `;

  return (
    <>
      <div className="cover--relative flex justify-center items-center h-full font-poppins bg-gray-100">
        <div className="max-w-[320px] mt-[15vh] sm:mt-0 sm:max-w-4xl">
          <header className="mb-3 mt-7 sm:mt-0">
            <div className="text-center sm:text-left">
              <a
                href="https://www.identi5id.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block"
              >
                <img
                  src={logoIdenti5IDFull}
                  alt="logo Identi5ID"
                  className="object-cover w-36"
                />
              </a>
            </div>
            <h3 className="text-primary">
              Inicia aquí{" "}
              <span className="font-bold">
                la demostración de esta poderosa solución de biometría facial
              </span>
            </h3>
          </header>
          <article className="h-[512px] mb-[20vh] sm:mb-0 sm:h-auto rounded-xl overflow-hidden bg-white shadow-lg shadow-slate-500 relative">
            <div className="grid grid-cols-2 z-10">
              <div className="col-span-2 sm:col-span-1 overflow-hidden">
                <img
                  src={userRegister}
                  alt=""
                  className="w-full h-48 sm:h-full object-cover"
                />
              </div>
              <div className="absolute sm:hidden top-3 right-3 grid justify-center items-center">
                <Link to="https://www.appinit.co/" target="_blank">
                  <p className="text-sm text-white">Powered by</p>
                  <img
                    src={logoAppinITFull}
                    alt="logo appinIT"
                    className="mx-auto mb-4 object-cover w-20"
                  />
                </Link>
              </div>
              <div className="col-span-2  sm:top-0 sm:col-span-1 relative bg-white -top-4 p-8 flex flex-col items-center justify-center shadow-3xl shadow-white z-20">
                <div className="relative -top-10 sm:top-0">
                  <p className="text-primary text-left sm:text-center relative z-30">
                    Ingresa AQUÍ tus datos
                  </p>
                  <FormClassic
                    onSubmit={onSubmit}
                    classes="max-w-[320px] text-center"
                  >
                    <InputForm
                      type="text"
                      pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]{5,40}$"
                      placeholder="Nombre completo"
                      maxLength={40}
                      minLength={5}
                      autoFocus={ true }
                      value={ temporaryData?.nickName }
                      classes=""
                      hint="* Ingresa tu nombre completo"
                      onChange={(e: any) =>
                        handleChange({
                          name: "nickName",
                          value: e.target.value,
                        })
                      }
                    />
                    <InputForm
                      type="email"
                      // pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                      placeholder="Correo electrónico"
                      maxLength={90}
                      hint="* Ingresa tu email"
                      value={temporaryData?.email}
                      classes=""
                      onChange={(e: any) =>
                        handleChange({ name: "email", value: e.target.value })
                      }
                    />
                    <InputCountryPhone
                      pattern="[0-9]{7,20}"
                      maxLength={20}
                      placeholder="Teléfono celular"
                      hint="* Ingresa tu número de celular"
                      value={temporaryData?.cellphone}
                      classes=""
                      onChange={(value: any) =>
                        handleChange({ name: "cellphone", value })
                      }
                    />
                    <div className="flex flex--center mb-2 mt-3 mx-2">
                      <p className="text-gray-500 text-left text-xs p-0 leading-4">
                        La seguridad y la privacidad de tus datos son nuestra prioridad. He leído y autorizo los{" "}
                        <strong>
                          <Link
                            className="text-primary"
                            to="/terminos-y-condiciones"
                          >
                            Términos y Condiciones
                          </Link>
                        </strong>
                        {" "}aquí relacionados.
                      </p>
                      <InputChoice
                        type="checkbox"
                        checked={acceptedTerms}
                        onChange={handleChoice}
                      />
                    </div>

                    <ButtonClassic
                      loading={loadingSignIn}
                      type="submit"
                      color="secondary"
                      text="Abrir plataforma"
                      classes="my-1"
                      disabled={!acceptedTerms}
                    />
                  </FormClassic>
                </div>
              </div>
            </div>
            <footer className="hidden sm:relative bg-primary w-full text-white text-sm sm:flex z-20">
              <div className="border-r-2 border-white w-72 flex items-center justify-center">
                <Link
                  to="https://www.appinit.co/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white underline text-xs"
                >
                  Powered by
                  <img
                    src={logoAppinIT}
                    alt="logo appinIT"
                    className="mx-auto object-cover w-20"
                  />
                </Link>
              </div>
              <p
                className="p-3 text-xs"
                dangerouslySetInnerHTML={{ __html: footerMessage }}
              ></p>
            </footer>
          </article>
        </div>
        <div className="fixed bottom-0 sm:hidden rounded-t-lg bg-primary w-full text-white text-sm z-20">
          <p
            className="p-3 text-xs"
            dangerouslySetInnerHTML={{ __html: footerMessage }}
          ></p>
        </div>
      </div>
    </>
  );
};
