import React, {useEffect} from 'react';

// Styles import
import "../../assets/styles/elements/elements.alerts.css";

//Assets import
import success from "../../assets/icons/success.svg";
import warning from "../../assets/icons/warning.svg";
import error from "../../assets/icons/error.svg";

interface IFAlertMessagesModal {
  classes: string;
  type: "success" | "warning" | "error"
  title: string;
  message: string;
  code: number;
  outsideClick:boolean;
  closeAlert(e: any): void;
}

const defaultProps = {
  classes: "",
  type: "warning",
  title: "Write title!",
  message: "Write information message!",
  code: 0,
  outsideClick: false,
  closeAlert: null
}

export const AlertMessagesModal = (props: IFAlertMessagesModal) => {

  const errorCode:any = {
    400: "error",
    500: "error",
    200: "success"
  }

  const iconType:any = {
    "success": {
      icon: success,
      color: {
        to: "to-green-600",
        text: "text-green-100",
        400: "bg-green-400",
        600: "bg-green-600",
      }
    },
    "warning": {
      icon: warning,
      color: {
        to: "to-amber-600",
        text: "text-white ",
        400: "bg-amber-400",
        600: "bg-amber-700",
      }
    },
    "error": {
      icon: error,
      color: {
        to: "to-red-500",
        text: "text-red-100",
        400: "bg-red-400",
        600: "bg-red-600",
      }
    }
  }

  //Validate if props code is passed or not
  const type = props.code > 0 ? errorCode[props.code] : props.type;
  const icon = iconType[type].icon

  const color = (payload:any) => {
    return iconType[type].color[payload]
  }

  const title = props.code ? `Código: ${props.code}` : props.title

  const handleGlobalClick = (e:any) =>{
    if(props.outsideClick){
      props.closeAlert(e.target.classList.contains('alert'))
    }
    props.closeAlert(e.target.classList.contains('closeAlert'))
  } 

  useEffect(()=>{
      window.addEventListener("click", handleGlobalClick)        
      //CleanUp click listener
      return () => window.removeEventListener('click', handleGlobalClick)
  })

  return (
    <div className="alert-modal bg-gray">
      <div className="alert alert-modal__background"></div>
      <div className={`alert-modal__content from-[#a5a3a3] ${ color("to") }`}>
        <button onClick={ handleGlobalClick } className="alert-modal__close closeAlert">
          X
        </button>
        <div className="alert-modal__details">
          <div>
            <span className={`alert-modal__icon alert-modal__icon--pulse ${ color(600) }`}></span>
            <span className={`alert-modal__icon relative ${ color(600) }`}>
              <img src={ icon } alt={`Icon ${ type }`} className={`${ color(400) } alert-modal__icon alert-modal__icon--shadow`} />
            </span>
          </div>
          <div>
            <h2 className={`alert-modal__title ${ color("text") }`}>{ title }</h2>
            <p className="alert-modal__message">{props.message}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

AlertMessagesModal.defaultProps = defaultProps;
