import React from 'react'

//Assets import
import logoIdenti5IDFull from "../../assets/img/logoIdenti5ID-full.svg";

interface IFStepsTitle{
    title:string;
    subtitle:string;
    classes:string;
}

const defaultProps = {
    title:'',
    subtitle:'',
    classes: ''
}

export const StepsTitle = (props:IFStepsTitle) => {
  return (
    <>
        <div className={`${ props.classes }`}>
            <div className="flex items-center h-16">
            <a
                href="https://www.identi5id.com/"
                target="_blank"
                rel="noopener noreferrer"
            >
                <img
                src={logoIdenti5IDFull}
                alt="logo Identi5ID"
                className="relative w-full object-cover top-0 left-0 z-20"
                />
            </a>"
            <div className="relative -ml-7 z-10 bg-main flex items-center pr-3 justify-end h-[42px] md:h-[50px] w-2/3">
                <p className="text-right font-bold text-2xl md:text-5xl text-white">{ props.title }</p>
                <span className="h-9 w-9 bg-white rounded-full absolute -left-5"></span>
            </div>
            </div>
            <div className="bg-primary text-white text-lg md:text-2xl font-bold rounded-bl-3xl rounded-br-3xl text-right py-2 md:py-3 pr-4 md:mt-2">
                { props.subtitle }
            </div>
        </div>
    </>
  )
}

StepsTitle.defaultProps = defaultProps
