import React, { useEffect, useRef, useState } from 'react';

interface IFInputSwitch {
  classes: string;
  size: string;
  colorEnable: string;
  colorDisable: string;
  disabled: boolean;
  checked: boolean;
  onChange(e: any): void;
}

const defaultProps = {
  classes: "",
  colorEnable: "bg-primary",
  colorDisable: "bg-gray-200",
  size: "7",
  disabled: false,
  checked: false,
  onChange: null
}

export const InputSwitch = (props: IFInputSwitch) => {

  const [colorSwitch, setColorSwitch] = useState(props.colorDisable)
  const inputElement = useRef(null)

  useEffect(() => {
    inputElement.current && handleChange(inputElement.current)
  }, [colorSwitch])

  const handleChange = (input: any) => {
    input.addEventListener("change", (e: any) => {
      const colorEnable = e.target.checked ? props.colorEnable : props.colorDisable
      setColorSwitch(colorEnable)
    })
  }



  return (
    <>
      <label className={`switch h-5 w-10 ${props.classes} ${colorSwitch} `}>
        <input
          ref={inputElement}
          type="checkbox"
          className="switch__input"
          onChange={props.onChange}
          checked={ props.checked }
          value=''
          disabled={ props.disabled }
        /><div className={`switch__button h-5 w-5`}></div>
      </label>
    </>
  );
}

InputSwitch.defaultProps = defaultProps;
